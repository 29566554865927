import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import backgroundImage from "../../backgrounds/whale.png";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";
import GetStarted from "components/cta/GetStarted.js";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures.js";

import { NavLinks } from "../../components/headers/light";

import TwoColWithButton from "components/features/TwoColWithButton.js";

import whaleGif from "../../lotties/whale/Comp-1.gif";

// only text module imports

import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription2 } from "components/misc/Typography.js";

export const BackLink = tw.a`
    text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
    text-gray-300
    font-semibold tracking-wide transition duration-300
    pb-1 border-transparent hover:border-gray-500 hocus:text-gray-500
  `;

// Only text

const HeaderContainerOther = tw.div`mt-10 w-full flex flex-col items-center`;
// const SubheadingOther = tw(SubheadingBase)`mb-4`;
const HeadingOther = tw(SectionHeading)`w-full text-left text-white `;
const DescriptionOther = tw(
  SectionDescription2
)`w-10/12 text-justify m-10 text-white`;

//  2 Col with Video

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;
const HeroContainer = tw.div` relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-20  px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full text-center flex flex-col items-center  sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 `;
const Description = tw.p`w-11/12 mt-4 text-justify md:text-justify text-sm md:text-white lg:text-lg font-medium leading-relaxed text-white`;
const DescriptionExtended = tw.p`
w-11/12 px-12 pb-20 text-justify 
md:text-justify text-sm 
md:text-white 
lg:text-lg font-medium leading-relaxed text-white`;

const Heading = styled.h3`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-5xl font-bold text-primary-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-white px-4 -mx-4 py-2`}
  ${
    "" /* &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`} */
  }
  }
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  width: 95%;
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

// for events and talks
// const ContainerCard = tw.div`text-center content-center ml-48 mr-12`;

// const ThreeColumnContainer = styled.div`
//   ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
// `;
// const HeadingCard = tw(SectionHeading)`w-full text-white`;

// const Column = styled.div`
//   ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
// `;
// const Card = styled.div`
//   ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-white rounded-lg mt-12`}
//   .textContainer {
//     ${tw`mt-6 text-center`}
//   }
//   .title {
//     ${tw`mt-2 font-bold text-xl leading-none text-white`}
//   }
//   .description {
//     ${tw`mt-3 font-semibold text-white text-sm leading-loose`}
//   }
// `;

const Whales = () => {
  const navLinks = [
    <NavLinks key={1}>
      <BackLink href="/#" tw="lg:ml-12!">
        Home
      </BackLink>
      <BackLink href="https://cosmic-zoom.in/events" tw="lg:ml-12!">
        Events
      </BackLink>
      <BackLink href="/about" tw="lg:ml-12!">
        About
      </BackLink>
      <BackLink href="/human" tw="lg:ml-12!">
        Smaller
      </BackLink>
      <BackLink href="/earth" tw="lg:ml-12!">
        Larger
      </BackLink>
    </NavLinks>,
  ];

  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-full transform scale-75 `;
  // const HeadingCss = tw`text-white`;
  // const DescriptionCss = tw`text-white `;

  const colors = { color: "#fff" };
  const bordercolor = { borderColor: "#fff" };

  const cardDetails = [
    {
      title: "Have You Ever Heard a Whale Sing?",
      description: "Divya Panicker",
      link:
        "https://www.youtube.com/watch?v=YzlZ9qNpGIA&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=10",
    },
    {
      title: "The life of whales and dolphins",
      description: "Dipani Sutaria ",
      link: "https://cosmic-zoom.in/events/",
    },
  ];

  return (
    <AnimationRevealPage>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          // backgroundSize: 'cover',
          backgroundRepeat: "repeat",
        }}
      >
        {/* Navbar */}

        <Header links={navLinks} />

        {/* Hero */}
        <TwoColWithButton
          heading={<>Whales</>}
          description="Whales are a very large marine mammal with a streamlined hairless body, a horizontal tail fin, and a blowhole on top of the head for breathing."
          imageSrc={whaleGif}
          imageCss={imageCss}
          textOnLeft={false}
        />

        {/* 1stExhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>Communication in Whales</SlantedBackground>
                </Heading>
                <Description>
                  Whales are among the largest creatures found on earth. The
                  blue whale is our planet’s largest animal. It measures around
                  25 meters in length – longer than a cricket pitch. It can
                  weigh up to 180 tonnes. An endangered species, there are only
                  25,000 blue whales living in the wild. The seas and oceans
                  provide them vast areas to move around. They use a variety of
                  low-frequency sounds to communicate with each other. These
                  sounds can travel for very long distances in water. This
                  enables the whales to navigate their way to feeding grounds
                  and also to communicate with potential mates.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/8Wt4pomro6M?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* add below video */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 2nd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/7Xr9BYhlceA?controls=1"
                  background="transparent"
                />
              </LeftColumn>

              <RightColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>Whale Songs</SlantedBackground>
                </Heading>
                <Description>
                  Humpback whales are known to sing complicated notes to attract
                  mates and to communicate with other males of its kind. A whale
                  song is made up of repeating sound sequences. Described as a
                  series of moans, chirps and cries, the notes vary in frequency
                  between 8 Hz and 4,000 Hz, partly audible to the human ear.
                  Other baleen whales including the blue whale are also known to
                  sing. Recent studies indicate that heavy maritime traffic –
                  military and cargo vessels – along busy trade routes can
                  hinder communication between individuals and whale groups.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </RightColumn>
            </TwoColumn>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 3rd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>Whale Migration</SlantedBackground>
                </Heading>
                <Description>
                  Just like the blue whales, gray whales also have baleen – a
                  comb-like structure in their mouth that helps them to trap
                  small invertebrates. Gray whales take mouthfuls of sediment
                  and invertebrates from the soft seafloor and sieve out water
                  and silt through their baleen. Gray whales are largely found
                  in two separate populations on the western and eastern ends of
                  the North Pacific Ocean. They are known for having one of the
                  longest migratory routes. They move between their winter
                  habitat or calving grounds near the coast of southern
                  California in the USA and their feeding grounds near the
                  arctic, totalling to over 20,000 kilometres annually.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/Ssh5NBB-JPU?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* add below video */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        {/* 4th Exhibit */}

        <HeaderContainerOther>
          {/* {<SubheadingOther>Exhibit 1</SubheadingOther>} */}
          <DescriptionOther>
            <HeadingOther style={{ textAlign: "center" }}>
              Whale Hunting
            </HeadingOther>
            Whaling or whale hunting is an ancient tradition in many coastal
            communities. Whales have been hunted for centuries for their meat
            and blubber. The Inuits, the indigenous community in Greenland, are
            among the few communities that even today practice their centuries
            old tradition of hunting whales for its meat. Blubber or body fat of
            whales had many industrial uses. For this reason, whale hunting
            reached its peak, with thousands of whales being slaughtered, during
            the industrial revolution in Europe between 1760-1840. Whale hunting
            today is banned in most countries. Established in 1946, the
            International Whaling Commission (IWC) overlooks the conservation
            measures and regulates hunting of whales. In 1986, it passed a ban
            on hunting of all whales for commercial purposes. However, Iceland,
            Norway and Japan continue to fund whaling expeditions in the Arctic
            and around Antarctica, where whales congregate to feed.
          </DescriptionOther>
        </HeaderContainerOther>

        {/* Talks and Events */}
        <DashedBorderSixFeatures
          cards={cardDetails}
          colorCss={colors}
          bordercolor={bordercolor}
        />

        {/* Quiz link */}
        <GetStarted primaryLinkUrl="https://forms.gle/jFzKi2tPbNTo2xCS6" />

        {/* Footer */}
        <MiniCenteredFooter />
      </div>
    </AnimationRevealPage>
  );
};

export default Whales;
