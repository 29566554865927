import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import backgroundImage from "../../backgrounds/stars.png";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";
import GetStarted from "components/cta/GetStarted.js";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures.js";

import { NavLinks } from "../../components/headers/light";

import TwoColWithButton from "components/features/TwoColWithButton.js";

import starGif from "../../lotties/star/1 star.gif";

// only text module imports

import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription2 } from "components/misc/Typography.js";
import starImage from "../../images/scalePages/Star_is_Born_Spoof.png";

export const BackLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  text-gray-300
  font-semibold tracking-wide transition duration-300
  pb-1 border-transparent hover:border-gray-500 hocus:text-gray-500
`;

// Only text

const HeaderContainerOther = tw.div`mt-10 w-full flex flex-col items-center`;
// const SubheadingOther = tw(SubheadingBase)`mb-4`;
const HeadingOther = tw(SectionHeading)`w-full text-left text-white `;
const DescriptionOther = tw(
  SectionDescription2
)`w-10/12 text-justify m-10 text-white`;

//  2 Col with Video

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;

const HeroContainer = tw.div` relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-20  px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full text-center flex flex-col items-center  sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 `;
const Description = tw.p`w-11/12 mt-4 text-justify md:text-justify text-sm md:text-white lg:text-lg font-medium leading-relaxed text-white`;
const DescriptionExtended = tw.p`
w-11/12 px-12 pb-20 text-justify 
md:text-justify text-sm 
md:text-white 
lg:text-lg font-medium leading-relaxed text-white`;

const Heading = styled.h3`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-5xl font-bold text-primary-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-white px-4 -mx-4 py-2`}
  ${
    "" /* &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`} */
  }
  }
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  width: 95%;
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

// for events and talks
// const ContainerCard = tw.div`text-center content-center ml-48 mr-12`;

// const ThreeColumnContainer = styled.div`
//   ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
// `;
// const HeadingCard = tw(SectionHeading)`w-full text-white`;

// const Column = styled.div`
//   ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
// `;
// const Card = styled.div`
//   ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-white rounded-lg mt-12`}
//   .textContainer {
//     ${tw`mt-6 text-center`}
//   }
//   .title {
//     ${tw`mt-2 font-bold text-xl leading-none text-white`}
//   }
//   .description {
//     ${tw`mt-3 font-semibold text-white text-sm leading-loose`}
//   }
// `;

const Star = () => {
  const navLinks = [
    <NavLinks key={1}>
      <BackLink href="/#" tw="lg:ml-12!">
        Home
      </BackLink>
      <BackLink href="https://cosmic-zoom.in/events" tw="lg:ml-12!">
        Events
      </BackLink>
      <BackLink href="/about" tw="lg:ml-12!">
        About
      </BackLink>
      <BackLink href="/solar-system" tw="lg:ml-12!">
        Smaller
      </BackLink>
      <BackLink href="/galaxies" tw="lg:ml-12!">
        Larger
      </BackLink>
    </NavLinks>,
  ];

  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-full transform scale-100 `;
  // const HeadingCss = tw`text-white`;
  // const DescriptionCss = tw`text-white `;

  const colors = { color: "#fff" };
  const bordercolor = { borderColor: "#fff" };

  const cardDetails = [
    {
      title: "A day with telescopes",
      description: "Indian Astronomical Observatory",
      link: "https://cosmic-zoom.in/events/",
    },
    {
      title: "The Story of Helium and the Birth of Astrophysics",
      description: "Biman Nath ",
      link:
        "https://www.youtube.com/watch?v=LQGspcta3EY&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=3",
    },
    {
      title: "The Impossible Dream of Neutrino Astronomy",
      description: "Basu Dasgupta",
      link:
        "https://www.youtube.com/watch?v=FY7IPffZJ9M&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=12",
    },
  ];

  return (
    <AnimationRevealPage>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          // backgroundSize: 'cover',
          backgroundRepeat: "repeat",
        }}
      >
        {/* Navbar */}

        <Header links={navLinks} />

        {/* Hero */}
        <TwoColWithButton
          heading={<>Stars</>}
          description={
            <>
              Stars are big burning balls of gas, powered by nuclear reactions
              happening in their cores. Inside stars, lighter elements like
              Hydrogen and Helium are combined to produce heavier elements like
              Carbon and Oxygen. At the end of their lifetime, many stars die in
              a massive explosion, releasing the newly synthesized elements that
              almost fills the entire periodic table. As Carl Sagan put it, we
              are made of this star stuff.
            </>
          }
          imageSrc={starGif}
          imageCss={imageCss}
          textOnLeft={false}
        />

        {/* 1st Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <Heading>
                  <SlantedBackground>A star is born!</SlantedBackground>
                </Heading>
                <Description>
                  Stars are born out of the gravitational pull of cold, dense
                  molecular gas clouds. As the cloud collapses, it fragments
                  into smaller regions. While the less massive fragments could
                  form planets, the massive ones will further contract to form{" "}
                  <i> protostars</i>. Due to this compression, these protostars
                  heat up and eventually nuclear fusion starts in their cores.
                  Some of the less massive protostars won’t generate high enough
                  temperatures to start significant nuclear fusion. These
                  unlucky stars, called
                  <i> brown dwarfs</i>, don’t get to shine like others! In
                  nuclear fusion, lighter elements are fused to form heavier
                  elements, releasing enormous energy. This energy arrests
                  further contraction of the star, providing an effective
                  balance against the gravitational force. Initially, hydrogen
                  is converted into helium, which continues for 90% of the
                  star’s life. Such hydrogen-burning stars, like our Sun, are
                  called <i> main sequence</i>
                  stars. The subsequent evolution of a star depends primarily on
                  its mass.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <img
                  src={starImage}
                  alt=""
                  style={{ borderRadius: "5px", width: "75%" }}
                />
              </RightColumn>
            </TwoColumn>
          </HeroContainer>
        </Container>

        <div style={{ height: "150px" }}></div>

        {/* 2nd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/PM9CQDlQI0A?controls=1"
                  background="transparent"
                />
              </LeftColumn>

              <RightColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>Evolution of stars </SlantedBackground>
                </Heading>
                <Description>
                  Once all the hydrogen is converted to helium, the energy
                  generation stops, leaving nothing to arrest the gravitational
                  contraction. The core contracts to higher densities to
                  eventually start the nuclear fusion of helium and that ignites
                  hydrogen in a shell surrounding the core. This leads to an
                  increased energy generation rate, leading the star to expand
                  enormously, becoming a red giant. Once all the helium inside
                  the core is converted into carbon, the core starts to shrink
                  again, raising the temperature. However, in stars with masses
                  less than about 8 solar masses, the contracting core never
                  gets hot enough to start the fusion of carbon.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>
              The contraction of the core is eventually stopped by the{" "}
              <i> electron degeneracy pressure </i>-- a pressure produced by the
              quantum mechanical nature of electrons in the core. No further
              contraction is possible and the core forms a <i> white dwarf</i>.
              The outer layers of the star are ejected as a{" "}
              <i> planetary nebula</i>. In the cores of more massive stars,
              heavier and heavier elements are formed, until the formation of
              iron. Iron cannot be burned further as it requires energy to
              continue the process. The star, therefore, runs out of fuel and
              collapses under its own gravity, forming a <i>neutron star</i> or
              a <i>black hole</i> in this process. This collapse can be
              associated with a luminous explosion called a <i>supernova</i>.
            </DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 3rd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>The real alchemists </SlantedBackground>
                </Heading>
                <Description>
                  For centuries, alchemists across the world tried to invent
                  ways of making “noble” elements like gold from other
                  inexpensive materials. We now know that converting one element
                  into another requires nuclear reactions, which cannot be
                  performed in usual laboratories. It turns out that stars are
                  real alchemists, in which new elements are synthesized through
                  nuclear fusion. Before stars appeared, the only elements that
                  existed in the Universe were hydrogen and helium. Most
                  elements that we see everyday are produced in the interior of
                  stars, including carbon, nitrogen, oxygen, etc. which are
                  essential for life. (However, stars cannot synthesize elements
                  heavier than iron). At the end of their lifetime, the cores of
                  massive stars collapse under the influence of gravity, while
                  the envelope explodes as a supernova. Elements heavier than
                  iron are believed to be synthesized in these explosions, or in
                  the collisions of neutron stars. This explosion spews large
                  amounts of the newly synthesized elements to the interstellar
                  space. The expelled material eventually clump together under
                  the influence of gravity and form new stars, planets and
                  eventually life forms. We are made of star stuff!
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/jf_4z4AKwJg?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 4th Exhibit */}

        <HeaderContainerOther>
          {/* {<SubheadingOther>Exhibit 1</SubheadingOther>} */}
          <DescriptionOther>
            <HeadingOther style={{ textAlign: "center" }}>
              Graveyard of stars
            </HeadingOther>
            Once a star runs out of nuclear fuel, gravity dominates and it
            collapses into a compact object -- a massive object with a very
            small radius and extremely high density. For example, the core of a
            less massive star collapses into a white dwarf. A white dwarf can be
            as massive as the Sun, but will have a size similar to the Earth. A
            teaspoon of white dwarf material would weigh about 15 tons! Young
            white dwarfs, such as Sirius B, are hot so that they emit{" "}
            <i> black body radiation </i> which we can detect. After billions of
            years, these objects cool down, eventually rendering them invisible.
            <br />
            Cores of more massive stars will collapse into neutron stars, which
            are even more dense objects. A neutron star that is as massive as
            the Sun will have the size of a city. A spoonful of neutron star
            material would weigh about 10 million tons. Neutron stars have
            typically large magnetic fields, which enables them to produce
            highly collimated emission of electromagnetic radiation. Such
            objects are observable as <i>pulsars</i>.
            <br />
            The most massive of stars collapse into <i> black holes</i>. A black
            hole is the most compact object that we know of. A black hole that
            is as massive as the Sun will have a radius of about 3 kilometers.
            The surface gravity of a black hole is so large that even light is
            unable to escape from it. Although black holes are invisible
            themselves, they can accrete gas from a neighboring star and produce
            electromagnetic emission.
          </DescriptionOther>
        </HeaderContainerOther>

        {/* Talks and Events */}
        <DashedBorderSixFeatures
          cards={cardDetails}
          colorCss={colors}
          bordercolor={bordercolor}
        />

        {/* Quiz link */}
        <GetStarted primaryLinkUrl="https://forms.gle/ydmTmi327u9cmCsv8" />

        {/* Footer */}
        <MiniCenteredFooter />
      </div>
    </AnimationRevealPage>
  );
};

export default Star;
