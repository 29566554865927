import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

import Main from "pages/Main/Main";
import Star from "pages/Star/Star";
import Atoms from "pages/Atoms/Atoms";
import Cell from "pages/Cell/Cell";
import Cmb from "pages/Cmb/Cmb";
import Earth from "pages/Earth/Earth";
import Galaxies from "pages/Galaxies/Galaxies";
import Insects from "pages/Insects/Insects";
import Nano from "pages/Nano/Nano";
import SolarSystem from "pages/SolarSystem/SolarSystem";
import Whales from "pages/Whales/Whales";
import Subatomic from "pages/Subatomic/Subatomic";
import Human from "pages/Human/Human";
import About from "pages/About/About";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  // return <AnimationRevealPage disabled></AnimationRevealPage>;
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/star" component={Star} />
        <Route exact path="/atoms" component={Atoms} />
        <Route exact path="/cell" component={Cell} />
        <Route exact path="/cmb" component={Cmb} />
        <Route exact path="/earth" component={Earth} />
        <Route exact path="/galaxies" component={Galaxies} />
        <Route exact path="/insects" component={Insects} />
        <Route exact path="/nano" component={Nano} />
        <Route exact path="/solar-system" component={SolarSystem} />
        <Route exact path="/whales" component={Whales} />
        <Route exact path="/subatomic" component={Subatomic} />
        <Route exact path="/human" component={Human} />
        <Route exact path="/about" component={About} />
      </Switch>
    </Router>
  );
}
