import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import backgroundImage from "../../backgrounds/subatomic.png";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures.js";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";

import { NavLinks } from "../../components/headers/light";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import { SectionDescription2 } from "components/misc/Typography.js";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings.js";

import TwoColWithButton from "components/features/TwoColWithButton.js";

import subAtomicGif from "../../lotties/subatomic/sub-atomic.gif";
import atomImage from "../../images/scalePages/Atom.png";

export const BackLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  text-gray-300
  font-semibold tracking-wide transition duration-300
  pb-1 border-transparent hover:border-gray-500 hocus:text-gray-500
`;

const HeaderContainerOther = tw.div`mt-10 w-full flex flex-col items-center`;

const HeadingOther = tw(SectionHeading)`w-full text-left text-black `;
const DescriptionOther = tw(
  SectionDescription2
)`w-10/12 text-justify m-10 text-black`;

// for events and talks
// const ContainerCard = tw.div`text-center content-center ml-48 mr-12`;

// const ThreeColumnContainer = styled.div`
//   ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
// `;
// const HeadingCard = tw(SectionHeading)`w-full text-black`;

// const Column = styled.div`
//   ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
// `;
// const Card = styled.div`
//   ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-black rounded-lg mt-12`}
//   .imageContainer {
//     ${tw`border-2 border-black text-center rounded-full p-6 flex-shrink-0 relative`}
//     img {
//       ${tw`w-8 h-8`}
//     }
//   }
//   .textContainer {
//     ${tw`mt-6 text-center`}
//   }
//   .title {
//     ${tw`mt-2 font-bold text-xl leading-none text-gray-800 font-bold`}
//   }
//   .description {
//     ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
//   }
// `;

//  2 Col with Video

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;
const HeroContainer = tw.div` relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-20  px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full text-center flex flex-col items-center  sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 `;
const Description = tw.p`w-11/12 mt-4 text-justify md:text-justify text-sm md:text-black lg:text-lg font-medium leading-relaxed text-black`;
const DescriptionExtended = tw.p`
w-11/12 
pb-20 text-justify px-12 
md:text-justify text-sm 
md:text-black 
lg:text-lg font-medium leading-relaxed text-black`;

const Heading = styled.h3`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-5xl font-bold text-primary-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-black px-4 -mx-4 py-2`}
  ${
    "" /* &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`} */
  }
  }
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  width: 95%;
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

const Subatomic = () => {
  const navLinks = [
    <NavLinks key={1}>
      <BackLink href="/#" tw="lg:ml-12!">
        Home
      </BackLink>
      <BackLink href="https://cosmic-zoom.in/events" tw="lg:ml-12!">
        Events
      </BackLink>
      <BackLink href="/about" tw="lg:ml-12!">
        About
      </BackLink>
      <BackLink href="/cmb" tw="lg:ml-12!">
        Largest
      </BackLink>
      <BackLink href="/atoms" tw="lg:ml-12!">
        Larger
      </BackLink>
    </NavLinks>,
  ];

  const colors = { color: "#000" };
  const bordercolor = { borderColor: "#000" };

  const cardDetails = [
    {
      title: "The fundamental building blocks of matter",
      description: "Sreerup Raychaudhuri ",
      link: "https://cosmic-zoom.in/events/",
    },
    {
      title: "The Impossible Dream of Neutrino Astronomy",
      description: "Basu Dasgupta",
      link: "https://cosmic-zoom.in/events/",
    },
  ];

  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-full transform scale-75 `;
  const HeadingCss = tw`text-black`;
  const DescriptionCss = tw`text-black `;

  return (
    <AnimationRevealPage>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          // backgroundSize: 'cover',
          backgroundRepeat: "repeat",
        }}
      >
        {/* Navbar */}

        <Header links={navLinks} />

        {/* Hero */}
        <TwoColWithButton
          heading={<>Subatomic Particles</>}
          description={
            <>
              All the normal matter that we know is made of fundamental building
              blocks called <i>quarks</i> and <i>leptons</i> , whose
              interactions are mediated by another class of fundamental
              particles called <i>bosons</i>. This bizarre microworld is
              governed by the laws of <i> quantum field theory</i>, and is
              intimately connected to the origin, evolution and the structure of
              the Universe at the largest scales!
            </>
          }
          imageSrc={subAtomicGif}
          imageCss={imageCss}
          textOnLeft={false}
          DescriptionCss={DescriptionCss}
          HeadingCss={HeadingCss}
          primaryButtonText="Order Now"
          watchVideoButtonText="Meet The Chefs"
        />

        {/* 1stExhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    Fundamental building blocks of matter
                  </SlantedBackground>
                </Heading>
                <Description>
                  Atoms are made up of even smaller particles. At the centre of
                  the atom lies the nucleus. The nucleus is densely packed, with
                  positively charged particles called protons and neutral
                  particles called neutrons. The nucleus is surrounded by a
                  cloud of negatively charged electrons. The electrons are among
                  the fundamental particles that constitute matter, whereas the
                  protons and neutrons are made up of even smaller particles
                  called <i>quarks</i>. While electrons are held in an atom by
                  the electromagnetic interaction between them and the nucleus,
                  quarks in a proton or neutron are held together by the{" "}
                  <i>strong nuclear force</i>. A remnant of the strong nuclear
                  force binds protons and neutrons inside the nucleus.{" "}
                  <i>The Standard Model of Particle Physics</i>
                  describes the fundamental building blocks of matter and the
                  interactions (except gravity) that hold them together.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                {/* <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/ehHoOYqAT_U?controls=1"
                  background="transparent"
                /> */}
                <img src={atomImage} alt="" />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended></DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 2nd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/ehHoOYqAT_U?controls=1"
                  background="transparent"
                />
              </LeftColumn>

              <RightColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    The Standard Model of Particle Physics
                  </SlantedBackground>
                </Heading>
                <Description>
                  The Standard Model of Particle Physics puts together all the
                  fundamental particles as well as their interactions through
                  the three fundamental forces -- the strong and weak nuclear
                  forces and the <i> electromagnetic force</i>. This theory has
                  successfully explained almost all experimental results and has
                  predicted a wide variety of phenomena. According to the
                  Standard Model, all the matter around us is made of two basic
                  types of particles, called quarks and leptons.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>
              There are six quarks (named
              <i> up, down, charm, strange, top and bottom </i>) and six leptons
              (named{" "}
              <i>
                electron, electron neutrino, muon, muon neutrino, tau and tau
                neutrino
              </i>
              ). Electrons, muons and tauons all have electric charge and mass.
              Neutrinos are electrically neutral and are predicted to be
              massless in the Standard Model; however actual measurements give a
              small but nonzero mass for them. Apart from these “matter
              particles,” the theory predicts “force carrier particles” that
              mediate three fundamental interactions in nature. The strong force
              is mediated by the <i> gluon</i>, the electromagnetic force is
              mediated by the photon, while the weak nuclear force is mediated
              by the W and Z bosons. The Standard Model also predicts the
              existence of another particle, called the <i> Higgs boson</i>,
              which is responsible for making some subatomic particles massive.
              The fourth fundamental interaction, gravity, is not included in
              the Standard Model. Combining gravity (described by Einstein’s
              General Theory of Relativity) and quantum theory has proven to be
              a challenging task for physicists.
            </DescriptionExtended>
          </HeroContainer>
        </Container>

        {/* 3rd Exhibit */}

        <HeaderContainerOther>
          {/* {<SubheadingOther>Exhibit 1</SubheadingOther>} */}
          <DescriptionOther>
            <HeadingOther style={{ textAlign: "center" }}>
              Particle colliders: A trip down memory lane
            </HeadingOther>
            As the name suggests, the strong force between quarks is extremely
            strong! Thus, splitting protons and neutrons to release the quarks
            requires enormous amounts of energy. Currently such energies are
            created in large particle colliders, such as the Large Hadron
            Collider in CERN, Geneva. However such high energies naturally
            existed in the Universe during the fractions of seconds following
            the Big Bang. At these extreme energies, quarks and gluons were
            bound only weakly and were free to move on their own. This
            astonishingly hot, dense soup of particles is known as the
            quark-gluon plasma. Due to its expansion, the Universe eventually
            “cooled down”, and quarks fused together to form protons and
            neutrons, which eventually combined with electrons and formed
            neutral atoms. Until then, the Universe was opaque to radiation. The
            formation of neutral atoms finally allowed the light to “escape,”
            creating the
            <a href="/cmb" style={{ color: "blue" }}>
              {" "}
              Cosmic Microwave Background radiation
            </a>{" "}
            that we observe today. By creating extremely high energies, particle
            colliders aim to reproduce environments similar to that existed in
            the early Universe -- a trip down memory lane!
          </DescriptionOther>
        </HeaderContainerOther>

        {/* 4th Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    Dark matter: Beyond Standard Model?
                  </SlantedBackground>
                </Heading>
                <Description>
                  Various astronomical observations suggest that only ~5% of the
                  total mass-energy of the Universe is in the form of “normal
                  matter” that is made of atoms. About 25% is in the form of
                  some unseen form of matter, called dark matter. It does not
                  absorb, reflect, or emit electromagnetic radiation, making it
                  hard to detect. However, dark matter exerts its gravitational
                  influence on other objects, such as stars. Astronomical
                  observations of the rotation speeds of stars in our{" "}
                  <a href="/galaxies" style={{ color: "blue" }}>
                    {" "}
                    galaxy{" "}
                  </a>
                  presents clear evidence of <i> dark matter</i>. Gravitational
                  influence of dark matter is observed on light also, through
                  the phenomenon of gravitational lensing. Light from distant
                  galaxies can be bent (or lensed) due to the gravitational
                  field of an intervening galaxy, producing distorted images of
                  the galaxy. By studying these distorted images, astronomers
                  can infer the mass of the lensing galaxy, which suggests that
                  galaxies are dominated by dark matter.
                  <br />
                  But what exactly is dark matter made of? Most scientists
                  believe that dark matter is made of some fundamental particles
                  that are not described in the Standard Model. Several ongoing
                  laboratory experiments and astronomical observations aim to
                  find evidence of these dark matter particles.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/QAa2O_8wBUQ?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended></DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 5th Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/nkydJXigkRE?controls=1"
                  background="transparent"
                />
              </LeftColumn>

              <RightColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    Neutrinos: Nature’s ghost particles
                  </SlantedBackground>
                </Heading>
                <Description>
                  Every second a continuous stream of the tiniest particles hits
                  the Earth at almost the speed of light. These subatomic
                  particles are called neutrinos. Neutrinos have no electric
                  charge and practically no mass. They interact with other
                  particles extremely weakly, making them hard to detect.
                  According to the Standard Model, neutrinos are massless.
                  However, observations suggest that neutrinos have a small
                  mass, requiring modifications to the Standard Model. Neutrinos
                  undergo spontaneous transitions from one type to another (say,
                  from an electron neutrino to a tau neutrino), called neutrino
                  oscillations. On the face of it, neutrinos look like a great
                  candidate for dark matter -- neutral, dark and elusive!
                  However, scientists have already ruled out this possibility.
                  Neutrinos are produced not only in terrestrial sources such as
                  nuclear reactors, but also copiously produced in the interior
                  of the Sun, supernova explosions, etc. Detecting neutrinos
                  from astrophysical sources can provide us with very
                  interesting information about the source, which complements
                  astronomical observations using light. Several neutrino
                  detectors are operational around the world, in all kinds of
                  exotic locations -- under the Antarctic ice, under the
                  mediterrenian sea, under the Kamioka mountain in Japan. Indian
                  scientists are in the process of building a neutrino
                  observatory in India also.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* add later */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* Talks and Events */}
        <DashedBorderSixFeatures
          cards={cardDetails}
          colorCss={colors}
          bordercolor={bordercolor}
        />

        {/* Footer */}
        <MiniCenteredFooter />
      </div>
    </AnimationRevealPage>
  );
};

export default Subatomic;
