import React from "react";

import tw from "twin.macro";
import styled from "styled-components";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures.js";

import backgroundImage from "../../backgrounds/galaxy.png";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";

import { NavLinks } from "../../components/headers/light";

import TwoColWithButton from "components/features/TwoColWithButton.js";

import galaxyGif from "../../lotties/galaxy/2galaxy.gif";

import tshirtIamge from "../../images/scalePages/tshirt.png";
import chartIamge from "../../images/scalePages/chart.PNG";
import quasarIamge from "../../images/scalePages/quasar.jpg";

// only text module imports

export const BackLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  text-gray-300
  font-semibold tracking-wide transition duration-300
  pb-1 border-transparent hover:border-gray-500 hocus:text-gray-500
`;

//  2 Col with Video

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;

const HeroContainer = tw.div` relative px-2 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-20  px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full text-center flex flex-col items-center  sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 `;
const Description = tw.p`w-11/12 mt-4 text-justify md:text-justify text-sm md:text-white lg:text-lg font-medium leading-relaxed text-white`;
const DescriptionExtended = tw.p`
w-11/12 px-12 pb-20 text-justify 
md:text-justify text-sm 
md:text-white 
lg:text-lg font-medium leading-relaxed text-white`;

const Heading = styled.h3`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-5xl font-bold text-primary-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-white px-4 -mx-4 py-2`}
  ${
    "" /* &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`} */
  }
  }
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  width: 95%;
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

// Only text

// const HeaderContainerOther = tw.div`mt-10 w-full flex flex-col items-center`;
// const SubheadingOther = tw(SubheadingBase)`mb-4`;
// const HeadingOther = tw(SectionHeading)`w-full text-left text-white `;
// const DescriptionOther = tw(
//   SectionDescription2
// )`w-auto text-justify m-10 text-white`;

const Galaxies = () => {
  const navLinks = [
    <NavLinks key={1}>
      <BackLink href="/#" tw="lg:ml-12!">
        Home
      </BackLink>
      <BackLink href="https://cosmic-zoom.in/events" tw="lg:ml-12!">
        Events
      </BackLink>
      <BackLink href="/about" tw="lg:ml-12!">
        About
      </BackLink>
      <BackLink href="/star" tw="lg:ml-12!">
        Smaller
      </BackLink>
      <BackLink href="/cmb" tw="lg:ml-12!">
        Larger
      </BackLink>
    </NavLinks>,
  ];

  const colors = { color: "#fff" };
  const bordercolor = { borderColor: "#fff" };

  const cardDetails = [
    {
      title: "The Search For Habitable Worlds",
      description: "Anand Narayanan",
      link:
        "https://www.youtube.com/watch?v=nVXLW4IQ6tk&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=14",
    },
    {
      title: "A day with telescopes",
      description: "Indian Astronomical Observatory",
      link: "https://cosmic-zoom.in/events/",
    },
  ];

  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-full transform scale-75 `;
  const HeadingCss = tw`text-white`;
  const DescriptionCss = tw`text-white `;

  return (
    <AnimationRevealPage>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          // backgroundSize: 'cover',
          backgroundRepeat: "repeat",
        }}
      >
        {/* Navbar */}

        <Header links={navLinks} />

        {/* Hero */}
        <TwoColWithButton
          heading={<>Galaxies</>}
          description={
            <>
              Galaxies are structures made of a large number of stars, with
              typical sizes of over 10 <sup>20</sup> meters. Our Sun is only one
              among the hundred billion stars in our galaxy, the Milky Way,
              which is only one among the trillions of galaxies in the Universe!
            </>
          }
          imageSrc={galaxyGif}
          imageCss={imageCss}
          textOnLeft={false}
        />

        {/* 1st Exhibit */}
        <TwoColWithButton
          heading={<>Our cosmic village: The Milky Way</>}
          description={
            <>
              Our solar system lies within the spiral-shaped galaxy Milky Way.
              Since we are part of it, the galaxy appears as a bright, luminous
              band in the night sky, like a river of stars! Ancient Indians
              called it Akasha Ganga --- Ganges in the skies. The Milky Way
              stretches about 50,000 light years. This means that light from one
              edge of the galaxy takes about 50,000 years to reach the other
              edge. The galaxy contains hundreds of billions of stars and
              planets, tens of millions of compact objects such as black holes,
              as well as huge lumps of gas and dust, all held together by
              gravity. It harbors a black hole that is a million times as heavy
              as our Sun at its center. Still, most of the mass in the galaxy is
              in the form of an unseen “dark matter”
            </>
          }
          imageSrc={tshirtIamge}
          imageCss={tw`rounded-md w-11/12  `}
          DescriptionCss={DescriptionCss}
          HeadingCss={HeadingCss}
          textOnLeft={true}
          primaryButtonText="Order Now"
          watchVideoButtonText="Meet The Chefs"
        />

        {/* 2nd Exhibit */}
        <TwoColWithButton
          heading={<>Dark matter in galaxies </>}
          description={
            <>
              Just like the orbital speeds of planets in the Solar System can be
              used to measure the mass of the Sun, the orbital speeds of stars
              in a galaxy can give us an estimate of the mass contained in the
              galaxy. In the solar system, orbital speeds of planets decrease
              along with their distance from the Sun --- distant planets orbit
              slower as compared to nearby planets. However, pioneering work by
              astronomer{" "}
              <a href="https://en.wikipedia.org/wiki/Vera_Rubin">Vera Rubin</a>{" "}
              showed that rotation speeds of stars in our galaxy do not follow
              this behavior. The only explanation is that our galaxy is filled
              with an unseen, dark matter that interacts with these stars
              gravitationally. Several astrophysical and cosmological
              observations suggest the presence of dark matter in all galaxies.
              Most physicists believe that dark matter is some new kind of
              elementary particle which we have not yet discovered in the
              laboratory. The search for these elusive particles is currently
              on.
            </>
          }
          imageSrc={chartIamge}
          imageCss={tw`rounded-md w-11/12  `}
          DescriptionCss={DescriptionCss}
          HeadingCss={HeadingCss}
          textOnLeft={false}
        />

        {/* 3rd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    Galaxy with a mysterious centre
                  </SlantedBackground>
                </Heading>
                <Description>
                  All stars in the Milky Way revolve around a central point in
                  which sits a monstrous black hole. Black holes are invisible,
                  but they make their presence felt through the gravitational
                  effect they have on the stars and gas around them. Two teams
                  of astronomers led by Andrea Ghez and Reinhard Genzel
                  painstakingly tracked the orbits of several stars at the
                  centre of our galaxy over the past two decades using the
                  world’s most powerful telescopes. Their observations revealed
                  that the galactic centre hosts a supermassive black hole that
                  is four million times as massive as the Sun. We can also
                  observe the light produced by matter falling into such black
                  holes at very high speeds. Radio astronomers have also
                  observed the “shadow” of a billion-solar-mass black hole at
                  the center of our neighboring galaxy M87.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/rxJgebvqzXA?controls=1"
                  // url={exhibitThreeVideo}
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended></DescriptionExtended>
          </HeroContainer>
        </Container>

        {/* 4th Exhibit */}
        <TwoColWithButton
          heading={<> Galaxies and supermassive black holes</>}
          description={
            <>
              The observable universe is filled with trillions of galaxies. All
              large galaxies seem to host <i>supermassive</i> black holes at
              their centres, each weighing millions to billions of solar masses.
              As surrounding material falls toward the black hole, its
              gravitational energy can be converted to light, making some of the
              galactic centre very bright. Ironically, the most luminous objects
              in the Universe, such as <i>quasars</i> , are powered by black
              holes. Some of these <i>active galactic nuclei</i> also shoot out
              colossal jets of material with speeds close to the speed of light,
              that travel thousands of light years. Although supermassive black
              holes appear to play a major role in the cosmic evolution of
              galaxies, it is not clear how these giants are produced in the
              first place. Scientists believe that these giants grew from small
              “seed black holes" which could be produced by the collapse of the
              first stars, or by the collapse of extremely dense regions in the
              early Universe.
            </>
          }
          imageSrc={quasarIamge}
          imageCss={tw`rounded-md w-11/12  `}
          DescriptionCss={DescriptionCss}
          HeadingCss={HeadingCss}
          textOnLeft={true}
          primaryButtonText="Order Now"
          watchVideoButtonText="Meet The Chefs"
        />
        {/* Talks and Events */}
        <DashedBorderSixFeatures
          cards={cardDetails}
          colorCss={colors}
          bordercolor={bordercolor}
        />

        {/* Footer */}
        <MiniCenteredFooter />
      </div>
    </AnimationRevealPage>
  );
};

export default Galaxies;
