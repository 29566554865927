import React from "react";
import tw from "twin.macro";

import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import backgroundImage from "../../backgrounds/atoms.png";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";

import TwoColWithButton from "components/features/TwoColWithButton";
import atomGif from "../../lotties/atom/Comp 1.gif";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";

import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import GetStarted from "components/cta/GetStarted.js";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures.js";

import { NavLinks } from "../../components/headers/light";

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;

const HeroContainer = tw.div` relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-20  px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full text-center flex flex-col items-center  sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 `;
const Description = tw.p`w-11/12 mt-4 text-justify md:text-justify text-sm md:text-black lg:text-lg font-medium leading-relaxed text-black`;

const DescriptionExtended = tw.p`
w-11/12 text-justify pl-12 mt-4
lg:mx-0
md:w-11/12
sm:w-11/12
sm:pl-10
md:text-justify
md:text-black
lg:text-lg
font-medium leading-relaxed text-black`;

const Heading = styled.h3`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-5xl font-bold text-primary-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-black px-4 -mx-4 py-2`}
  ${
    "" /* &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`} */
  }
  }
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  width: 95%;
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

// For exhibits without images

// const HeaderContainerOther = tw.div` w-full flex flex-col items-center`;
// const SubheadingOther = tw(SubheadingBase)`mb-4`;
// const HeadingOther = tw(SectionHeading)`w-full`;
// const DescriptionOther = tw(SectionDescription2)`w-3/4 text-center m-10`;

export const BackLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  text-gray-300
  font-semibold tracking-wide transition duration-300
  pb-1 border-transparent hover:border-gray-500 hocus:text-gray-500
`;

const Atoms = () => {
  const navLinks = [
    <NavLinks key={1}>
      <BackLink href="/#" tw="lg:ml-12!">
        Home
      </BackLink>
      <BackLink href="https://cosmic-zoom.in/events" tw="lg:ml-12!">
        Events
      </BackLink>
      {/* <BackLink href="/#" tw="lg:ml-12!">
          About
      </BackLink> */}

      <BackLink href="/subatomic" tw="lg:ml-12!">
        Smaller
      </BackLink>
      <BackLink href="/nano" tw="lg:ml-12!">
        Larger
      </BackLink>
    </NavLinks>,
  ];

  const colors = { color: "#000" };
  const bordercolor = { borderColor: "#000" };

  const cardDetails = [
    {
      title: "On Quantum Voyages ",
      description: "Smitha Vishveshwara",
      link:
        "https://www.youtube.com/watch?v=8OccTkncdkQ&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=7",
    },
    {
      title: "The Story of Helium and the Birth of Astrophysics",
      description: "Biman Nath",
      link:
        "https://www.youtube.com/watch?v=LQGspcta3EY&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=3",
    },
  ];

  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-full transform scale-75 `;
  const HeadingCss = tw`text-black`;
  const DescriptionCss = tw`text-black `;

  return (
    <AnimationRevealPage>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          // backgroundSize: 'cover',
          backgroundRepeat: "repeat",
        }}
      >
        {/* Navbar */}

        <Header links={navLinks} />

        <div style={{ height: "50px" }}></div>

        {/* Hero */}
        <TwoColWithButton
          heading={<>Atoms</>}
          description={
            <>
              An atom is the smallest constituent unit of ordinary matter that
              has the properties of a chemical element. Understanding its
              structure and its properties has led to far reaching developments
              in science & technology. Our conception of the structure of the
              atom has undergone several changes through history and forms a
              fascinating part of the evolution of physics..
            </>
          }
          imageSrc={atomGif}
          imageCss={imageCss}
          HeadingCss={HeadingCss}
          DescriptionCss={DescriptionCss}
          textOnLeft={false}
          primaryButtonText="Order Now"
          watchVideoButtonText="Meet The Chefs"
        />

        {/* 1stExhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    Evolution of the idea of atom
                  </SlantedBackground>
                </Heading>
                <Description>
                  The abstract philosophical idea that matter is made of tiny
                  indivisible units, all identical to one another, had existed
                  for thousands of years. The term <i>atom</i> is derived from
                  the Greek word <i>Atomos</i>, meaning “indivisible.” An early
                  version of the atomic model that we know today was proposed by
                  John Dalton in 1808. He assumed that all atoms of an element
                  were identical, and atoms of one element could not be changed
                  into atoms of another element.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/xazQRcSCRaY?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>
              In 1897, physicist JJ Thompson discovered a sub-atomic particle,
              called the electron, which changed the idea that atoms are
              indivisible. Ernest Rutherford put forward the idea of the{" "}
              <i>nuclear model</i> for the atom. In this model, all the positive
              charge in an atom is located in a central nucleus. Electrons move
              around the nucleus in orbits similar to those of planets around
              the Sun. However, this model had a serious problem: The model
              predicted that the atom would not be stable, because the orbiting
              electrons would radiate energy and collapse into the nucleus!{" "}
              <br />
              Eventually, this problem was resolved by quantum theory. In the
              Bohr model of atom, electrons are allowed to move only in a finite
              set of orbits. Jumping between these orbits will require the
              emission or absorption of a photon (quantum particle of light)
              with energy equal to the difference in the energy levels of the
              orbits. Modern quantum theory further refined the atomic model.
              The planetary model of the atom was discarded in favor of one that
              describes atomic orbital zones around the nucleus where a given
              electron is <i>most likely</i> to be observed. This model was able
              to explain all the experimental results so far.
            </DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 2nd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/fPnwBITSmgU?controls=1"
                  background="transparent"
                />
              </LeftColumn>

              <RightColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    Periodic table explained
                  </SlantedBackground>
                </Heading>
                <Description>
                  The periodic table organizes all discovered chemical elements
                  in rows (called <i>periods</i>) and columns (called{" "}
                  <i>groups</i>) according to increasing atomic number (i.e, the
                  total number of protons in the nucleus). When elements are
                  arranged in this way, there is a recurring pattern in their
                  properties -- elements in the same group have similar
                  properties. This is called the
                  <i>periodic law</i>. For example, Group 1 consists of Alkali
                  metals that are highly reactive with water, while Group 18
                  consists of non-reactive noble gases.
                  <br />
                  While many scientists worked on the problem of organizing the
                  elements, Dmitri Mendeleev published his first version of the
                  periodic table in 1869. Since then, it has evolved to reflect
                  over 150 years of scientific development and understanding. It
                  has even been used to predict the existence of undiscovered
                  elements. Out of the 118 elements discovered so far, only 94
                  exist in nature. The remaining 24 have only been created in
                  laboratories.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* later */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 3rd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    Fingerprint of elements: Spectroscopy
                  </SlantedBackground>
                </Heading>
                <Description>
                  According to quantum physics, electrons in an atom can occupy
                  only certain fixed energy levels. Electrons can ‘jump’ from
                  one level to another by absorbing or emitting a photon (the
                  quantum particle of light) that has an energy equal to the
                  difference between these energy levels. Since each element has
                  a unique number of electrons, an atom will absorb/release
                  energy in a pattern unique to that element. Thus, the energy
                  and hence the frequency of the emitted/absorbed photon is a
                  fingerprint of the particular element. Similarly, when
                  molecules undergo changes from one quantized energy state to
                  another, they also emit/absorb light of characteristic
                  frequencies. Using spectroscopy -- the study of the spectrum
                  of light emitted/absorbed by various materials -- scientists
                  have identified the fingerprint of various elements in the
                  lab. By comparing the spectrum of astronomical observations
                  with laboratory measurements, we can identify the presence of
                  various elements in stars, galaxies, etc. The frequency shifts
                  of specific spectral lines (Doppler shift) are also used to
                  measure the relative motion of the source, the gravitational
                  time dilation, and even the expansion of the Universe! The
                  electronic transitions happening at the smallest quantum
                  scales are our prime tool to study the Universe in the largest
                  scales!
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/h2kzT6CEhVs?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* later */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        {/* 4th Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/QTC1aKBgyUA?controls=1"
                  background="transparent"
                />
              </LeftColumn>

              <RightColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    The surprising history of Helium discovery
                  </SlantedBackground>
                </Heading>
                <Description>
                  Although rare on Earth, helium is the second most abundant
                  element in the Universe (after Hydrogen). The nuclear fusion
                  of Hydrogen forming Helium is what powers the Sun. In fact,
                  Helium derives its name from <i>Helios</i> --- the Greek Sun
                  god. The discovery of Helium has an interesting Indian
                  connection. According to popular history, the first evidence
                  of Helium in the Sun was observed by French astronomer Jules
                  Jansen in 1868 at Guntur, a town in the Indian state of Andhra
                  Pradesh. Jansen observed a bright yellow line of wavelength
                  587.49 nanometres in the spectrum of the Sun’s chromosphere
                  during a complete solar eclipse, which he interpreted as due
                  to a new element. However, this popular history may not be
                  even correct, as we will hear from a lecture by astrophysicist
                  <a href="http://www.cosmic-zoom.in/events">Biman Nath</a>.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* later */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* Talks and Events */}
        <DashedBorderSixFeatures
          cards={cardDetails}
          colorCss={colors}
          bordercolor={bordercolor}
        />

        {/* Quiz link */}
        <GetStarted primaryLinkUrl="https://forms.gle/pPX9QiUepixoVSjTA" />

        {/* Footer */}
        <MiniCenteredFooter />
      </div>
    </AnimationRevealPage>
  );
};

export default Atoms;
