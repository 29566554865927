import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import backgroundImage from "../../backgrounds/solar.png";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";
import GetStarted from "components/cta/GetStarted.js";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures.js";

import { NavLinks } from "../../components/headers/light";

import TwoColWithButton from "components/features/TwoColWithButton.js";

import solarGif from "../../lotties/solar-system/6Solar.gif";
import paleDotImage from "../../images/scalePages/Pale_Blue_Dot.png";
import marsImage from "../../images/scalePages/Mars_Perseverance.png";
import systemImage from "../../images/scalePages/system.JPG";
// only text module imports

export const BackLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  text-gray-300
  font-semibold tracking-wide transition duration-300
  pb-1 border-transparent hover:border-gray-500 hocus:text-gray-500
`;

// Only text

//  2 Col with Video

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;

const HeroContainer = tw.div` relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-20  px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full text-center flex flex-col items-center  sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 `;
const Description = tw.p`
w-11/12 mt-4 text-justify
md:text-justify text-sm 
md:text-white 
lg:text-lg 
font-medium leading-relaxed text-white`;
const DescriptionExtended = tw.p`
w-11/12 px-12 pb-20 text-justify 
md:text-justify text-sm 
md:text-white 
lg:text-lg font-medium leading-relaxed text-white`;

const Heading = styled.h3`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-5xl font-bold text-primary-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-white px-4 -mx-4 py-2`}
  ${
    "" /* &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`} */
  }
  }
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  width: 95%;
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

// for events and talks
// const ContainerCard = tw.div`text-center content-center ml-48 mr-12`;

// const ThreeColumnContainer = styled.div`
//   ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
// `;
// const HeadingCard = tw(SectionHeading)`w-full text-white`;

// const Column = styled.div`
//   ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
// `;
// const Card = styled.div`
//   ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-white rounded-lg mt-12`}
//   .textContainer {
//     ${tw`mt-6 text-center`}
//   }
//   .title {
//     ${tw`mt-2 font-bold text-xl leading-none text-white`}
//   }
//   .description {
//     ${tw`mt-3 font-semibold text-white text-sm leading-loose`}
//   }
// `;

const Star = () => {
  const navLinks = [
    <NavLinks key={1}>
      <BackLink href="/#" tw="lg:ml-12!">
        Home
      </BackLink>
      <BackLink href="https://cosmic-zoom.in/events" tw="lg:ml-12!">
        Events
      </BackLink>
      <BackLink href="/about" tw="lg:ml-12!">
        About
      </BackLink>
      <BackLink href="/earth" tw="lg:ml-12!">
        Smaller
      </BackLink>
      <BackLink href="/star" tw="lg:ml-12!">
        Larger
      </BackLink>
    </NavLinks>,
  ];

  const colors = { color: "#fff" };
  const bordercolor = { borderColor: "#fff" };

  const cardDetails = [
    {
      title: "The Search For Habitable Worlds",
      description: "Anand Narayanan ",
      link:
        "https://www.youtube.com/watch?v=nVXLW4IQ6tk&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=14",
    },
    {
      title: "Solar System: A Testbed for Physical Theories",
      description: "H R Madhusudan",
      link:
        "https://www.youtube.com/watch?v=43i2bfK-73M&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=11",
    },
  ];

  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-full transform scale-75 `;
  // const HeadingCss = tw`text-white`;
  // const DescriptionCss = tw`text-white `;

  return (
    <AnimationRevealPage>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          // backgroundSize: 'cover',
          backgroundRepeat: "repeat",
        }}
      >
        {/* Navbar */}

        <Header links={navLinks} />

        {/* Hero */}
        <TwoColWithButton
          heading={<>Solar System</>}
          description={
            <>
              Our solar system is located in the Orion spiral arm of the Milky
              Way. A gravitationally bound system, it includes eight planets,
              many satellites, asteroids, and dwarf planets, all revolving
              around the Sun.
            </>
          }
          imageSrc={solarGif}
          imageCss={imageCss}
          textOnLeft={false}
          primaryButtonText="Order Now"
          watchVideoButtonText="Meet The Chefs"
        />

        {/* 1stExhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>The Pale Blue Dot </SlantedBackground>
                </Heading>
                <Description>
                  Voyager 1 spacecraft was launched by NASA in 1977 to explore
                  Jupiter and Saturn by flying near them. After making a series
                  of discoveries, such as active volcanoes on Jupiter's moon Io,
                  the spacecraft drifted to the outer Solar System. Before
                  leaving the Solar System, Voyager looked back and took one
                  last photograph of the Earth. Taken from a distance of 6
                  billion kilometers, this photograph had practically no
                  scientific value; but it gave a unique perspective on
                  humanity's place in the Universe. In the photograph, Earth
                  appears as a tiny dot against the vastness of space, among
                  bands of sunlight reflected by the camera, like “a mote of
                  dust suspended in a sunbeam.” As Carl Sagan reminds us, “Earth
                  is a very small stage in a vast cosmic arena.”
                  <br />
                  Voyager 1 left the Solar System for ever in August 2012 and
                  continues to send us messages from interstellar space.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <img
                  src={paleDotImage}
                  alt=""
                  style={{ borderRadius: "5px" }}
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* add below video */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        {/* 2nd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}

                <img src={systemImage} alt="" style={{ borderRadius: "5px" }} />
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <Heading>
                  <SlantedBackground>
                    Solar system as a laboratory for fundamental physics
                  </SlantedBackground>
                </Heading>
                <Description>
                  To the curious and creative astronomers and other thinkers of
                  nature, the Solar System is a laboratory on astronomical size.
                  Observation of solar system objects, especially that of planet
                  Mars, first with the simplest of tools, namely our eye,
                  followed by Tycho Brahe’s precision instruments, led to the
                  formulation of Laws of Planetary motion by Johannes Kepler.
                  Newton’s work on the motion of the Moon explained these laws
                  and motion of <i>all</i> objects under gravitation. The
                  crowning moment for this Law of Universal Gravitation came
                  when astronomers observing the perturbations in the orbit of
                  Uranus predicted and discovered a new planet, Neptune.
                  <br />
                  Telescopic observations of the phases of Venus and satellites
                  of Jupiter by Galileo provided strong evidence for a
                  heliocentric model of the Solar System – a totally
                  non-intuitive model for observers stationed on the Earth! The
                  precession of perihelion of Mercury could be fully accounted
                  for by Einstein’s Theory of Gravitation – the General Theory
                  of Relativity. Gravitational bending of light, a prediction of
                  General Relativity, was spectacularly verified during a total
                  solar eclipse in 1919. Tracking the distance between the Moon
                  and the Earth using Lunar Laser Ranging experiments have put
                  extremely tight constraints on the possible time variation of
                  the gravitational constant <i>G</i>.
                </Description>
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* add below video */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        {/* 3rd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    Jupiter’s Giant Red Spot
                  </SlantedBackground>
                </Heading>
                <Description>
                  The biggest planet in the solar system, Jupiter, is about 11
                  times the size of the Earth and is about 300 times more
                  massive. Unlike the Earth, its surface is not a solid crust.
                  It is instead made of layers of thick swirling clouds of gases
                  around a hot liquid interior. It's these gaseous clouds that
                  give Jupiter its vibrant colours. Take a close look of Jupiter
                  and it is hard to miss the large red spot in its southern
                  hemisphere. This Giant Red Spot is a high-intensity storm that
                  has raged for hundreds of years, covering an area bigger than
                  the Earth! <br /> In July 2019, NASA sent a space mission
                  named Juno to Jupiter. It captured close and detailed images
                  of the Giant Red Spot, throwing more light on its size. The
                  images indicate that the giant red spot is shrinking in size,
                  changing shape from elliptical to spherical. What would the
                  red spot look like from within? View this realistic simulated
                  video by NASA on how travelling through Jupiter’s Giant Red
                  Spot might look like.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/uj3Lq7Gu94Y?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* add below video */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        {/* 4th Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <img src={marsImage} alt="" style={{ borderRadius: "10px" }} />
              </LeftColumn>

              <RightColumn style={{ marginLeft: "3rem" }}>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    {" "}
                    Mars: Why do we keep going back?
                  </SlantedBackground>
                </Heading>
                <Description>
                  In February this year, NASA’s Mars rover Perseverance
                  successfully landed on Mars. The curiosity to explore Mars is
                  not new. Scientists have always been interested in finding
                  another planet that potentially supports life. Mars being our
                  neighbour and with similarities in size and composition was
                  one of the obvious candidates to explore. Since 1960, when the
                  USSR sent the first ever mission to Mars, there has been a
                  steady launch of probes with complex and ambitious tasks. Some
                  attempts have been a success while some others weren’t. There
                  are several orbiters, landers and rovers that currently
                  explore various aspects of the Red Planet. Mars is not massive
                  enough to hold on to a thick layer of atmosphere through its
                  gravity. However, the presence of small amounts of methane in
                  Mars’s atmosphere has made scientists hopeful in their search
                  for signs of life. This is because methane is formed either as
                  a by-product of organic compounds breaking down or is released
                  during geothermal processes. Various missions have identified
                  the presence of water -- frozen water in the poles as well as
                  liquid water under the surface. Scientists are now speculating
                  whether conditions underground could have helped life forms
                  survive by escaping the harsh environment overground.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* later */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        {/* 5th Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>Other solar systems</SlantedBackground>
                </Heading>
                <Description>
                  Humans knew of the existence of Solar System planets out to
                  Saturn since ancient times. Uranus and Neptune were discovered
                  in the eighteenth and nineteenth centuries using telescopes.
                  However, the very first planet outside our Solar System was
                  discovered only in 1992. By the turn of the century,
                  astronomers had discovered 30 such exoplanets. To date, over
                  4,000 exoplanets have been discovered. Astronomers estimate
                  our galaxy to host hundreds of billions of such planets. While
                  most known exoplanets are a part of other star systems, there
                  are also a few free-roaming “rogue planets” that remain
                  untethered to any star
                  <br /> Exoplanets come in a variety of sizes, compositions,
                  and distances from their host stars. Some are rocky planets
                  like the Earth; some are gas giants larger than Jupiter.
                  Scientists have uncovered the presence of volcanoes and molten
                  seas on the surface of some of them. There are exoplanets that
                  are similar in size and composition as that of the Earth. Some
                  of them are even located in the host star’s habitable zone ﹣
                  the range of distances where conditions may be just right to
                  allow the presence of liquid water on the surface. But do any
                  of these Earth-like exoplanets actually support life? We don’t
                  yet know. like.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/EUU0-ZpFoK4?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* add below video */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        {/* Talks and Events */}
        <DashedBorderSixFeatures
          cards={cardDetails}
          colorCss={colors}
          bordercolor={bordercolor}
        />

        {/* Quiz link */}
        <GetStarted primaryLinkUrl="https://forms.gle/mU5jK7KfUrW8dBbY7" />

        {/* Footer */}
        <MiniCenteredFooter />
      </div>
    </AnimationRevealPage>
  );
};

export default Star;
