import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";
import { NavLinks } from "../../components/headers/light";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures.js";

import TwoColWithButton from "components/features/TwoColWithButton.js";
import backgroundImage from "../../backgrounds/human.png";
import humanGif from "../../lotties/human/Human-01.png";

// only text module imports

// import { SectionHeading } from "components/misc/Headings.js";
// import { SectionDescription2 } from "components/misc/Typography.js";

export const BackLink = tw.a`
    text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
    text-gray-300
    font-semibold tracking-wide transition duration-300
    pb-1 border-transparent hover:border-gray-500 hocus:text-gray-500
  `;

// Only text

// const HeaderContainerOther = tw.div`mt-10 w-full flex flex-col items-center`;
// // const SubheadingOther = tw(SubheadingBase)`mb-4`;
// const HeadingOther = tw(SectionHeading)`w-full text-left text-white `;
// const DescriptionOther = tw(
//   SectionDescription2
// )`w-3/4 text-justify m-10 text-white`;

//  2 Col with Video

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;

const HeroContainer = tw.div` relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-20  px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full text-center flex flex-col items-center  sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 `;
const Description = tw.p`w-11/12 mt-4 text-justify md:text-justify text-sm md:text-white lg:text-lg font-medium leading-relaxed text-white`;
const DescriptionExtended = tw.p`
w-11/12 px-12 pb-20 text-justify 
md:text-justify text-sm 
md:text-white 
lg:text-lg font-medium leading-relaxed text-white`;

const Heading = styled.h3`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-5xl font-bold text-primary-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-white px-4 -mx-4 py-2`}
  ${
    "" /* &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`} */
  }
  }
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  width: 95%;
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

// for events and talks
// const ContainerCard = tw.div`text-center content-center ml-48 mr-12`;

// const ThreeColumnContainer = styled.div`
//   ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
// `;
// const HeadingCard = tw(SectionHeading)`w-full text-white`;

// const Column = styled.div`
//   ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
// `;
// const Card = styled.div`
//   ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-white rounded-lg mt-12`}
//   .textContainer {
//     ${tw`mt-6 text-center`}
//   }
//   .title {
//     ${tw`mt-2 font-bold text-xl leading-none text-white`}
//   }
//   .description {
//     ${tw`mt-3 font-semibold text-white text-sm leading-loose`}
//   }
// `;

const Human = () => {
  const navLinks = [
    <NavLinks key={1}>
      <BackLink href="/#" tw="lg:ml-12!">
        Home
      </BackLink>
      <BackLink href="https://cosmic-zoom.in/events" tw="lg:ml-12!">
        Events
      </BackLink>
      <BackLink href="/about" tw="lg:ml-12!">
        About
      </BackLink>
      <BackLink href="/insects" tw="lg:ml-12!">
        Smaller
      </BackLink>
      <BackLink href="/whales" tw="lg:ml-12!">
        Larger
      </BackLink>
    </NavLinks>,
  ];

  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-full transform scale-75 `;
  // const HeadingCss = tw`text-white`;
  // const DescriptionCss = tw`text-white `;
  const colors = { color: "#fff" };
  const bordercolor = { borderColor: "#fff" };

  const cardDetails = [
    {
      title: "What’s in a diet?",
      description: "Anura Kurpad",
      link:
        "https://www.youtube.com/watch?v=aQVEwuFv6qQ&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=2",
    },
  ];

  return (
    <AnimationRevealPage>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          // backgroundSize: 'cover',
          backgroundRepeat: "repeat",
        }}
      >
        {/* Navbar */}

        <Header links={navLinks} />

        {/* Hero */}
        <TwoColWithButton
          heading={<>Human</>}
          description={
            <>
              Humans are arguably the most evolved species on Earth. Our
              cognitive abilities, such as the ability to ask deep questions
              about our place in the Universe, are a result of a long process of
              biological and cultural evolution
            </>
          }
          imageSrc={humanGif}
          imageCss={imageCss}
          textOnLeft={false}
          primaryButtonText="Order Now"
          watchVideoButtonText="Meet The Chefs"
        />

        {/* 1stExhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>The story of humans</SlantedBackground>
                </Heading>
                <Description>
                  Fossils suggest early human forms resembling the great apes,
                  originated around six million years ago in Africa. In fact,
                  Chimpanzees, Gorillas, and humans might have shared common
                  ancestors. The last of our common ancestors may have been{" "}
                  <i>Sahelanthropus</i> or the <i>Orrorin</i>, which lived 2.8
                  million years ago. Modern humans are highly evolved forms of
                  their ancestors with many morphological, physiological,
                  anatomical, and behavioural changes. Today we (
                  <i>Homo sapiens sapiens</i>) are the only species of humans
                  present on Earth.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/ehV-MmuvVMU?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* add below video */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 2nd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/vTSFmmvGX-c?controls=1"
                  background="transparent"
                />
              </LeftColumn>

              <RightColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>Bipedalism</SlantedBackground>
                </Heading>
                <Description>
                  The most significant change in humans from their ape-like
                  ancestors is bipedalism (walking on two legs). This perhaps
                  occurred around 6-7 million years ago. Being bipedal freed
                  hands, straightened backbones and modified the structure of
                  the hip bone, among many other things. But the modification of
                  hips to accommodate a bipedal lifestyle, narrowed the birthing
                  canal in females thereby constraining the size of the head of
                  human new-borns. The constraint on head size meant the size of
                  the brain increased post-natal, thus increasing the young
                  one’s dependency on its parents. Modern humans have the
                  largest brain among all primate species ﹣ three times larger
                  than the brains of gorillas and chimpanzees, our closest
                  living relatives. This gives us humans high cognition levels
                  that enables us to develop a large array of cultural and
                  behavioural traits such as social learning and language
                  acquisition.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </RightColumn>
            </TwoColumn>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 3rd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>Human migration</SlantedBackground>
                </Heading>
                <Description>
                  Though humans originated in Africa, today we are one of the
                  most globally widespread species. How did this happen? The
                  story of human migration is not a straightforward one.
                  Scientists had proposed many hypotheses as to the ways in
                  which this migration could have occurred. Two among them were
                  strong contenders.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/CJdT6QcSbQ0?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>
              {/* add below video */}
              The first, the <i>multi-regional theory</i>, suggested that an
              ancestral hominid species dispersed out of Africa to different
              parts of the globe and later evolved into modern humans at several
              locations. On the contrary, the second <i>Out of Africa</i> theory
              expressed that modern humans evolved in Africa and thrived there
              for many thousand years before spreading to other parts of the
              world. With very little fossil evidence present, it was difficult
              for scientists to determine which among the two theories was true.
              However, in the 1980’s scientists were able to compare the genetic
              material recovered from fossils to people from different parts of
              the world. Mitochondrial DNA was used to trace the lineage of
              humans back to Africa, thereby supporting the ‘Out of Africa’
              hypothesis. By further piecing together genetic information with
              fossil and archaeological evidence, scientists have been able to
              provide a timeline on how the migration of modern humans out of
              Africa took place.
              <br />
              Modern humans first migrated into Asia, following the coastline,
              around 80 to 60 thousand years ago, and further, to Australasia
              around 45 thousand years ago. They crossed over to Europe around
              40 thousand years ago, via land bridges near present-day Turkey
              and along the Mediterranean Sea. This may have brought them in
              close proximity to another human species inhabiting parts of
              Europe around the same time ﹣ the Neanderthals. The Neanderthals
              were outcompeted and went extinct 15,000 years ago. Modern humans
              then crossed into North America via the Bering land bridge ﹣ a
              thin strip of land that once connected North America with northern
              Europe, around 15,000 years ago and then moved further down to
              South America.
              <br />
              But why did modern humans migrate from Africa in the first place?
              Evidence suggests that perhaps prolonged periods of drought
              divided and scattered the human population in Africa into smaller
              groups, and the challenging conditions perhaps was the trigger for
              the migration to take place.
            </DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* Talks and Events */}
        {/* Talks and Events */}
        <DashedBorderSixFeatures
          cards={cardDetails}
          colorCss={colors}
          bordercolor={bordercolor}
        />

        {/* Footer */}
        <MiniCenteredFooter />
      </div>
    </AnimationRevealPage>
  );
};

export default Human;
