import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
// import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

// import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

// import SupportIconImage from "../../images/support-icon.svg";
// import ShieldIconImage from "../../images/shield-icon.svg";
// import CustomizeIconImage from "../../images/customize-icon.svg";
// import FastIconImage from "../../images/fast-icon.svg";
// import ReliableIconImage from "../../images/reliable-icon.svg";
// import SimpleIconImage from "../../images/simple-icon.svg";

const ContainerCard = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const HeadingCard = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-black rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-black text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-black`}
  }

  .description {
    ${tw`mt-3 font-semibold text-gray-800 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = [
    {
      title: "EFG",
      description: "Shashi Thutupalli, ICTS   ",
      link: "https://youtube.com",
    },
  ],
  colorCss = null,
  bordercolor = null,
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key
   * (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  // const cards = [
  //   {
  //     imageSrc: ShieldIconImage,
  //     title: "Event 1",
  //     description:
  //       "Some details about the events or the talks like time, date, speaker..etc ",
  //   },
  //   { imageSrc: SupportIconImage, title: "Event 2" },
  //   { imageSrc: CustomizeIconImage, title: "Event 3" },
  //   { imageSrc: ReliableIconImage, title: "Event 4" },
  //   { imageSrc: FastIconImage, title: "Event 5" },
  //   { imageSrc: SimpleIconImage, title: "Event 6" },
  // ];

  return (
    <ContainerCard>
      <ThreeColumnContainer>
        <HeadingCard>
          <span style={colorCss}>Events</span>
        </HeadingCard>
        {cards.map((card, i) => (
          <Column key={i}>
            <a href={card.link} target="_blank" rel="noopener noreferrer">
              <Card style={bordercolor}>
                {/* <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span> */}
                <span className="textContainer">
                  <span className="title" style={colorCss}>
                    {card.title || "Fully Secure"}
                  </span>
                  <p className="description" style={colorCss}>
                    {card.description ||
                      "Some details about the events or the talks like time, date, speaker..etc "}
                  </p>
                </span>
              </Card>
            </a>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </ContainerCard>
  );
};
