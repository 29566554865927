import React from "react";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import styled from "styled-components";
import backgroundImage from "../../backgrounds/insect.png";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import { SectionDescription2 } from "components/misc/Typography.js";

import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

// import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
import TwoColWithButton from "components/features/TwoColWithButton.js";

import GetStarted from "components/cta/GetStarted.js";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures.js";

import insectGif from "../../lotties/insects/Comp 1_1.gif";

import { NavLinks } from "../../components/headers/light";

import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;

const Heading = styled.h3`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-5xl font-bold text-primary-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-gray-800 px-4 -mx-4 py-2`}
  ${
    "" /* &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`} */
  }
  }
`;

const Description = tw.p`w-11/12 mt-4 text-justify md:text-justify text-sm md:text-gray-800 lg:text-lg font-medium leading-relaxed text-gray-800`;

const DescriptionExtended = tw.p`
w-11/12 px-12 pb-20 text-justify 
md:text-justify text-sm 
md:text-gray-800 
lg:text-lg font-medium leading-relaxed text-gray-800`;

// const PrimaryAction = tw.button`px-8 py-3  mt-8 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-primary-500 text-gray-100 font-bold rounded shadow transition duration-300 hocus:bg-primary-300 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  width: 95%;
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

const HeroContainer = tw.div` relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-20  px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full text-center flex flex-col items-center  sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 `;

// For exhibits without images

const HeaderContainerOther = tw.div`mt-10 w-full flex flex-col items-center`;
// const SubheadingOther = tw(SubheadingBase)`mb-4`;
const HeadingOther = tw(SectionHeading)`w-full text-justify text-gray-800 `;
const DescriptionOther = tw(
  SectionDescription2
)`w-10/12 text-justify m-10 text-gray-800`;

export const BackLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  text-gray-300
  font-semibold tracking-wide transition duration-300
  pb-1 border-transparent hover:border-gray-500 hocus:text-gray-500
`;

// const ContainerCard = tw.div`text-center content-center ml-48 mr-12`;

// const ThreeColumnContainer = styled.div`
//   ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
// `;
// const HeadingCard = tw(SectionHeading)`w-full text-gray-800`;

// const Column = styled.div`
//   ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
// `;

// const Card = styled.div`
//   ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-black rounded-lg mt-12`}
//   .imageContainer {
//     ${tw`border-2 border-black text-center rounded-full p-6 flex-shrink-0 relative`}
//     img {
//       ${tw`w-8 h-8`}
//     }
//   }

//   .textContainer {
//     ${tw`mt-6 text-center text-gray-800`}
//   }

//   .title {
//     ${tw`mt-2 font-bold text-xl leading-none text-gray-800 font-bold`}
//   }

//   .description {
//     ${tw`mt-3 font-semibold text-gray-800 text-sm leading-loose`}
//   }
// `;

const Insects = () => {
  const navLinks = [
    <NavLinks key={1}>
      <BackLink href="/#" tw="lg:ml-12!">
        Home
      </BackLink>
      <BackLink href="https://cosmic-zoom.in/events" tw="lg:ml-12!">
        Events
      </BackLink>
      <BackLink href="/about" tw="lg:ml-12!">
        About
      </BackLink>
      <BackLink href="/cell" tw="lg:ml-12!">
        Smaller
      </BackLink>
      <BackLink href="/human" tw="lg:ml-12!">
        Larger
      </BackLink>
    </NavLinks>,
  ];

  const colors = { color: "#000" };
  const bordercolor = { borderColor: "#000" };

  const cardDetails = [
    {
      title: "Organisms and Size:  Why Size Matters",
      description: "Amitabh Joshi and TNC Vidya",
      link:
        "https://www.youtube.com/watch?v=QrhRzlNcwcA&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=4",
    },
    {
      title: "How do insects fly?",
      description: "The Insect flight lab (NCBS)",
      link:
        "https://www.youtube.com/watch?v=SN3NXFsAuzE&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=13",
    },
    {
      title: "The interesting life of the giant honey bee",
      description: "Axel Brockmann ",
      link: "https://cosmic-zoom.in/events/",
    },
  ];
  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-full transform scale-75 `;
  const HeadingCss = tw`text-gray-800`;
  const DescriptionCss = tw`text-gray-800 `;

  return (
    <AnimationRevealPage>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          // backgroundSize: 'cover',
          backgroundRepeat: "repeat",
        }}
      >
        {/* Navbar */}

        <Header links={navLinks} />

        <div style={{ height: "50px" }}></div>

        {/* Hero */}
        <TwoColWithButton
          heading={<>Insects </>}
          description={
            <>
              Small as they might be, insects have fascinating lives. Many
              insects exhibit complex collective behavior. Insects like bees
              play very important roles in an ecosystem. They have been around
              for hundreds of millions of years.{" "}
            </>
          }
          imageSrc={insectGif}
          DescriptionCss={DescriptionCss}
          HeadingCss={HeadingCss}
          imageCss={imageCss}
          textOnLeft={false}
        />

        {/* 1st Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>Insect Flight</SlantedBackground>
                </Heading>
                <Description>
                  Insects have lived on earth for a very long time, much before
                  the dinosaurs. Scientists believe it is their ability to fly
                  that makes them one of the most successful groups of
                  organisms. Have you ever tried to catch a winged insect? A
                  mosquito or a fly or a bee? If so, you would have realised
                  that it’s not easy. Insects are equipped with highly evolved
                  and advanced flight mechanisms that enable them to manoeuvre
                  and steer at sharp angles at very high speeds. Their wings
                  however are very simple structures, loosely attached to the
                  outer surface of their body. Unlike in birds, the movement of
                  their wings is not controlled by the nervous system. It is a
                  mechanical movement controlled by muscles. There are
                  structures in the thorax of flies that help them maintain wing
                  coordination when flying.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/HoXJX6KmQd0?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended></DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 2nd exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/z4d2nNQAXms?controls=1"
                  background="transparent"
                />
              </LeftColumn>

              <RightColumn>
                <Heading>
                  <SlantedBackground>Insects in peril</SlantedBackground>
                </Heading>
                <Description>
                  They bite, they sting and sometimes they also destroy crops.
                  But nonetheless, insects play very important roles in an
                  ecosystem. They aren’t just food to many organisms, but they
                  are also responsible for pollination of over one third of the
                  food plants. Scientists estimate that the annual global
                  production of food relying on the services on insect
                  pollinators amounts to USD 235-577 billion.Without insect
                  pollinators, both our plate and palette would suffer, as the
                  majority of the food we consume everyday fruits, vegetables,
                  spices and legumes are insect pollinated plants.
                </Description>
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended></DescriptionExtended>
          </HeroContainer>
        </Container>

        {/* 3rd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>Honeybees and math</SlantedBackground>
                </Heading>
                <Description>
                  Did you know building a honeycomb involves math? Yes, you
                  heard it right. The tiny honey bees that build large hives to
                  store honey follow mathematical principles. Observe a
                  honeycomb and you will notice that the cells within which
                  honey is stored are hexagonal cells. Why such a marvel?
                  Beeswax is a valuable commodity. Bees produce it by converting
                  sugar from nectar into wax, a very energy consuming process.
                  Hence they need to be economical and build a stable structure
                  with the least amount of wax. A hexagonal grid is the best way
                  to divide a surface into regions of equal area with the least
                  total perimeter. Hexagonal cells are not only compact in size
                  as they share their walls with the neighbouring cells, but
                  they also don’t create wastage of space between adjacent
                  cells.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/QEzlsjAqADA?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended></DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 4th exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/Kq65MOLx4es?controls=1"
                  background="transparent"
                />
              </LeftColumn>

              <RightColumn>
                <Heading>
                  <SlantedBackground>
                    Dung beetles and the galaxy{" "}
                  </SlantedBackground>
                </Heading>
                <Description>
                  Dung beetles are among the strongest creatures on Earth as
                  they are able to carry more than a thousand times their body
                  weight. Dung beetles make large balls of animal dung and roll
                  it down to their underground nests. They lay their eggs in
                  these dung balls and on hatching, the larvae feed on nutrients
                  found in it. In 2013 scientists made an exciting discovery.
                  Nocturnal species of dung beetles were found to rely on the
                  night sky to navigate their way to and from their nests.
                  Previously scientists believed that dung beetles find their
                  way in the dark using wind direction and moonlight. However,
                  scientists noticed that their movement and activities were not
                  disrupted on cloudy or moonless nights, giving rise to the
                  idea that the night sky had a role to play. To confirm this,
                  researchers scattered a few dung beetles on a large table
                  inside a planetarium, with a view of the night sky overhead.
                  They found that the beetles were able to reorient their
                  direction with respect to the night sky, especially the light
                  band, as the Milky Way is seen from Earth. But, when this view
                  was blocked by placing broad brimmed hats on the beetles, they
                  moved around cluelessly, confirming that they relied on the
                  Milky Way band to find their way home.
                </Description>
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended></DescriptionExtended>
          </HeroContainer>
        </Container>

        {/* 5th Exhibit */}

        <HeaderContainerOther>
          {/* {<SubheadingOther>Exhibit 1</SubheadingOther>} */}
          <DescriptionOther>
            <HeadingOther style={{ textAlign: "center" }}>
              The Cell
            </HeadingOther>
            Honey Bees store honey in small hexagonal compartments called
            ‘cells’ in their hives. It was this honeycomb pattern that the
            English scientist Robert Hooke was reminded of when he observed a
            thin slice of cork under a microscope and saw tiny compartment like
            structures, that he termed the ‘Cell’ in 1665. Cells are the
            smallest units of life, the “building blocks of life.” What Hooke
            had seen was the cell wall of dead plant cells. Learn more about
            cells and their many functions in the Cell exhibit.
          </DescriptionOther>
        </HeaderContainerOther>

        {/* Talks and Events */}
        <DashedBorderSixFeatures
          cards={cardDetails}
          colorCss={colors}
          bordercolor={bordercolor}
        />

        {/* Quiz link */}
        <GetStarted primaryLinkUrl="https://forms.gle/ruRWwJqRe9HZMiNv6" />

        {/* Footer */}
        <MiniCenteredFooter />
      </div>
    </AnimationRevealPage>
  );
};

export default Insects;
