import React from "react";
import tw from "twin.macro";

import { SectionHeading } from "components/misc/Headings.js";
// import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import { SectionDescription2 } from "components/misc/Typography.js";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";

import Header from "components/headers/light.js";

import { NavLinks } from "../../components/headers/light";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures.js";

import TwoColWithButton from "components/features/TwoColWithButton.js";
import backgroundImage from "../../backgrounds/cmb.png";

import cmbGif from "../../lotties/cmb/Comp-1.gif";

import universeImage from "../../images/scalePages/Universe-diagram.jpg";
import blackBodyImage from "../../images/scalePages/black_body.png";
import radioImage from "../../images/scalePages/Horn-antenna-used.ppm";
import cmbImage from "../../images/scalePages/CMBimage.png";
import GetStarted from "components/cta/GetStarted";

export const BackLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  text-gray-300
  font-semibold tracking-wide transition duration-300
  pb-1 border-transparent hover:border-gray-500 hocus:text-gray-500
`;

const HeaderContainerOther = tw.div`mt-10 w-full flex flex-col items-center`;
// const SubheadingOther = tw(SubheadingBase)`mb-4`;
const HeadingOther = tw(SectionHeading)`w-full text-left text-black `;
const DescriptionOther = tw(
  SectionDescription2
)`w-auto text-justify m-10 text-black`;

// for events and talks
// const ContainerCard = tw.div`text-center content-center ml-48 mr-12`;

// const ThreeColumnContainer = styled.div`
//   ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
// `;
// const HeadingCard = tw(SectionHeading)`w-full text-black`;

// const Column = styled.div`
//   ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
// `;
// const Card = styled.div`
//   ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-black rounded-lg mt-12`}
//   .imageContainer {
//     ${tw`border-2 border-black text-center rounded-full p-6 flex-shrink-0 relative`}
//     img {
//       ${tw`w-8 h-8`}
//     }
//   }
//   .textContainer {
//     ${tw`mt-6 text-center`}
//   }
//   .title {
//     ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
//   }
//   .description {
//     ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
//   }
// `;

//  2 Col with Video

// const HeroContainer = tw.div` relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
// const TwoColumn = tw.div`pt-20  px-4 flex justify-between items-center flex-col lg:flex-row`;
// const LeftColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 flex flex-col items-center lg:block`;
// const RightColumn = tw.div`w-full text-center flex flex-col items-center  sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 `;
// const Description = tw.p`w-11/12 mt-4 text-justify md:text-justify text-sm md:text-black lg:text-lg font-medium leading-relaxed text-black`;
// const DescriptionExtended = tw.p`
// w-full px-12 pb-20 text-justify
// md:text-justify text-sm
// md:text-black
// lg:text-lg font-medium leading-relaxed text-black`;

// const Heading = styled.h3`
//   ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-5xl font-bold text-primary-100 leading-none`}
//   span {
//     ${tw`inline-block mt-2`}
//   }
// `;

const Cmb = () => {
  const navLinks = [
    <NavLinks key={1}>
      <BackLink href="/#" tw="lg:ml-12!">
        Home
      </BackLink>
      <BackLink href="https://cosmic-zoom.in/events" tw="lg:ml-12!">
        Events
      </BackLink>
      {/*  <BackLink href="/about" tw="lg:ml-12!">
        About
      </BackLink> 
      */}

      <BackLink href="/galaxies" tw="lg:ml-12!">
        Smaller
      </BackLink>
      <BackLink href="/subatomic" tw="lg:ml-12!">
        Smallest
      </BackLink>
    </NavLinks>,
  ];

  const colors = { color: "#000" };
  const bordercolor = { borderColor: "#000" };

  const cardDetails = [
    {
      title: "The fundamental building blocks of matter ",
      description: "Sreerup Raychaudhuri ",
      link: "https://cosmic-zoom.in/events/",
    },
  ];

  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-full transform scale-75 `;
  const HeadingCss = tw`text-black`;
  const DescriptionCss = tw`text-black `;

  return (
    <AnimationRevealPage>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          // backgroundSize: 'cover',
          backgroundRepeat: "repeat",
        }}
      >
        {/* Navbar */}

        <Header links={navLinks} />

        {/* Hero */}
        <TwoColWithButton
          heading={<>The Universe</>}
          description={
            <>
              The size of the Observable Universe is about 10 <sup>26</sup>
              meters (~50 billion light years). The Universe could actually be
              much bigger than this. However, our observations are limited by
              the horizon of the light that can reach us. Currently the largest
              distance that we can see is through the observation of the cosmic
              microwave background (CMB) radiation.
            </>
          }
          imageSrc={cmbGif}
          imageCss={imageCss}
          HeadingCss={HeadingCss}
          DescriptionCss={DescriptionCss}
          textOnLeft={false}
          primaryButtonText="Order Now"
          watchVideoButtonText="Meet The Chefs"
        />

        {/* 1stExhibit */}

        <TwoColWithButton
          heading={<>A snapshot of the past</>}
          description={
            <>
              This image shows the faint glow of microwave radiation from the
              early Universe. The Universe is filled with this CMB radiation —
              wherever we look in the sky, we see it with remarkable
              consistency. By about 380,000 years after the Big Bang, the
              Universe had expanded and cooled enough to form neutral atoms.
              Until then, the Universe was so hot and dense that subatomic
              particles existed outside atoms. No light could travel through
              this hot, dense soup of charged particles. Once neutral atoms
              formed, the photons could freely travel through the Universe,
              creating the CMB that we see today. Produced about 13 billion
              years ago, this first light from the Universe has helped us
              understand its origin and composition with unprecedented levels of
              precision and accuracy.
            </>
          }
          imageSrc={cmbImage}
          imageCss={tw`rounded-md w-11/12  `}
          DescriptionCss={DescriptionCss}
          HeadingCss={HeadingCss}
          textOnLeft={true}
        />
        {/* 2nd exhibit */}

        <TwoColWithButton
          heading={
            <>The serendipitous discovery of the Cosmic Microwave Background </>
          }
          description={
            <>
              In 1964, American astronomers Arno Penzias and Robert Wilson from
              Bell Labs were studying radio signals from the Milky Way galaxy.
              They observed a persistent static noise in their radio antenna.
              This “noise” did not seem to come from any particular direction in
              the sky. They investigated all possible sources of the noise,
              including droppings from pigeons roosting on their 20-feet
              antenna! Even after shooing away the pigeons and clearing the
              droppings, the noise persisted. It took them a while to realize
              they had stumbled upon something profound. Theoretical work by P.
              J. E. Peebles from Robert Dicke’s group in Princeton University
              had predicted the existence of a relic black body radiation from
              the early Universe that should be observable at microwave
              frequencies. Dicke’s group was already working on experiments in
              search of this radiation. They helped Penzias and Wilson to
              confirm that the annoying noise that bothered them was, in fact,
              the radiation from the early Universe.
            </>
          }
          imageSrc={radioImage}
          imageDescription={<p style={{ color: "#000" }}></p>}
          imageCss={tw`rounded-md w-11/12  `}
          DescriptionCss={DescriptionCss}
          HeadingCss={HeadingCss}
          textOnLeft={false}
        />

        {/* 3rd exhibit */}

        <HeaderContainerOther>
          {/* {<SubheadingOther>Exhibit 1</SubheadingOther>} */}

          <HeadingOther style={{ textAlign: "center" }}>
            Black body radiation
          </HeadingOther>
          <div
            style={{
              width: "75%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <img src={blackBodyImage} alt="" style={{}} />
            <p style={{ textAlign: "center", paddingTop: "1rem" }}>
              Black body radiation from our body (left), hot coals (middle) and
              the Sun (right). Since the infrared radiation (left) is invisible
              to our eyes, we need to use pseudo coloring.
            </p>
          </div>
          <DescriptionOther>
            All hot objects radiate. The spectrum of this thermal radiation is
            entirely determined by the temperature of the radiating object: the
            hotter an object is, the higher is the frequency of the radiation.
            For example, hot coals glow in red-orange since they have a
            temperature of several hundred degrees. Slightly cooler objects,
            like our body (temperature ~ 30 degrees), radiate in infrared
            wavelengths. Although our eyes cannot see this glow, infrared
            cameras can. That’s why they are used to monitor body temperature.
            Since the early Universe was quite hot (about 3000 degrees when it
            was ~380,000 years old), it produced a black body radiation
            primarily in infrared. Due to the expansion of the Universe, its
            frequencies red-shifted to the lower, microwave frequencies. The
            corresponding temperature of a black body is about −270 degree
            celsius (2.7 Kelvin). The Universe is filled with this microwave
            radiation, so that this is responsible for part of the noise that we
            see in the old (analog) television sets!
          </DescriptionOther>
        </HeaderContainerOther>

        {/* 4th- exhibit */}

        <TwoColWithButton
          heading={<>Formation of stars and galaxies</>}
          description={
            <>
              The CMB radiation is coming from every direction of the sky with
              nearly uniform intensity, with variations of only 1 part in
              100,000. These variations are due to the random fluctuations in
              the density and temperature of the Universe at the time of
              creation of the CMB. Those tiny fluctuations later grew into all
              the large-scale structures that we see today. Gravity made dense
              regions denser, extremely dense regions collapsed under their own
              self gravity to form stars, and stars assembled to form galaxies.
              Over time, galaxies merged with each other to form more massive
              galaxies and galaxy clusters. This process of structure formation
              is the result of a complex interplay of gravity, fluid dynamics
              and all sorts of physics. However, cosmologists broadly understand
              this process --- the distribution of galaxies that we see in the
              current Universe is consistent with the primordial fluctuations
              that we see in the CMB. This is like comparing the portrait of an
              adult with his or her baby picture!
            </>
          }
          imageSrc={universeImage}
          imageDescription={
            <p style={{ color: "#000" }}>
              An artistic image representing the evolution of the Universe over
              13.8 billion years. This involves the Big Bang on the left, an
              initial burst of exponential growth called <i>Inflation</i>,
              followed by a gradual slowing of the Universe's expansion and,
              more recently, an acceleration of growth due to the{" "}
              <i>Dark Energy.</i>
            </p>
          }
          imageCss={tw`rounded-md w-11/12  `}
          DescriptionCss={DescriptionCss}
          HeadingCss={HeadingCss}
          textOnLeft={false}
        />

        {/* 5th Exhibit */}

        <HeaderContainerOther>
          {/* {<SubheadingOther>Exhibit 1</SubheadingOther>} */}
          <DescriptionOther>
            <HeadingOther style={{ textAlign: "center" }}>
              Expanding Universe and Dark Energy
            </HeadingOther>
            By 1930 we knew from the work of Hubble, Friedmann, Lemaitre and
            other contemporary scientists that the Universe is expanding. Since
            the Universe is full of matter and that gravity is an attractive
            force, the general understanding around that time was that the
            expansion should slow down with time. However, in 1998 using the
            observations of distant supernovae with the help of the Hubble Space
            Telescope, astronomers found that instead of slowing down, the
            Universe is expanding faster and faster! To explain this accelerated
            expansion, theorists came up with the idea of “Dark Energy”. It
            could be either a fundamental property of space, or a new kind of
            dynamical energy that fills the space. The exact nature of dark
            energy is still a mystery. However, while normal matter tries to
            slow down the expansion, dark energy should provide a repulsive
            force to speed up the expansion. Various cosmological observations
            suggest that about 68% of the total mass-energy of the Universe is
            in the form of this mysterious energy.
          </DescriptionOther>
        </HeaderContainerOther>

        <div style={{ height: "100px" }}></div>

        {/* Talks and Events */}
        <DashedBorderSixFeatures
          cards={cardDetails}
          colorCss={colors}
          bordercolor={bordercolor}
        />
        {/* Quiz link */}
        <GetStarted primaryLinkUrl="https://forms.gle/wJwzhRe1tMhNJZPo9" />

        {/* Footer */}
        <MiniCenteredFooter />
      </div>
    </AnimationRevealPage>
  );
};

export default Cmb;
