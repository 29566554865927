import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import backgroundImage from "../../backgrounds/nano.png";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";
import GetStarted from "components/cta/GetStarted.js";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures.js";

import { NavLinks } from "../../components/headers/light";

import TwoColWithButton from "components/features/TwoColWithButton.js";

import nanoGif from "../../lotties/nano/Comp-1.gif";
import waterImage from "../../images/scalePages/H2O_Molecule.png";
// only text module imports

import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription2 } from "components/misc/Typography.js";

export const BackLink = tw.a`
    text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
    text-gray-300
    font-semibold tracking-wide transition duration-300
    pb-1 border-transparent hover:border-gray-500 hocus:text-gray-500
  `;

// Only text

const HeaderContainerOther = tw.div`mt-10 w-full flex flex-col items-center`;
// const SubheadingOther = tw(SubheadingBase)`mb-4`;
const HeadingOther = tw(SectionHeading)`w-full text-left text-white `;
const DescriptionOther = tw(
  SectionDescription2
)`w-11/12 text-justify m-10 text-white`;

//  2 Col with Video

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;

const HeroContainer = tw.div` relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-20  px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full text-center flex flex-col items-center  sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 `;
const Description = tw.p`w-11/12 mt-4 text-justify md:text-justify text-sm md:text-white lg:text-lg font-medium leading-relaxed text-white`;
const DescriptionExtended = tw.p`
w-11/12 px-12 pb-20 text-justify 
md:text-justify text-sm 
md:text-white 
lg:text-lg font-medium leading-relaxed text-white`;

const Heading = styled.h3`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-5xl font-bold text-primary-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-white px-4 -mx-4 py-2`}
  ${
    "" /* &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`} */
  }
  }
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  width: 95%;
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

// for events and talks
// const ContainerCard = tw.div`text-center content-center ml-48 mr-12`;

// const ThreeColumnContainer = styled.div`
//   ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
// `;
// const HeadingCard = tw(SectionHeading)`w-full text-white`;

// const Column = styled.div`
//   ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
// `;
// const Card = styled.div`
//   ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-white rounded-lg mt-12`}
//   .textContainer {
//     ${tw`mt-6 text-center`}
//   }
//   .title {
//     ${tw`mt-2 font-bold text-xl leading-none text-white`}
//   }
//   .description {
//     ${tw`mt-3 font-semibold text-white text-sm leading-loose`}
//   }
// `;

const Nano = () => {
  const navLinks = [
    <NavLinks key={1}>
      <BackLink href="/#" tw="lg:ml-12!">
        Home
      </BackLink>
      <BackLink href="https://cosmic-zoom.in/events" tw="lg:ml-12!">
        Events
      </BackLink>
      <BackLink href="/about" tw="lg:ml-12!">
        About
      </BackLink>
      <BackLink href="/atoms" tw="lg:ml-12!">
        Smaller
      </BackLink>
      <BackLink href="/cell" tw="lg:ml-12!">
        Larger
      </BackLink>
    </NavLinks>,
  ];

  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-full transform scale-75 `;
  // const HeadingCss = tw`text-white`;
  // const DescriptionCss = tw`text-white `;

  const colors = { color: "#fff" };
  const bordercolor = { borderColor: "#fff" };

  const cardDetails = [
    {
      title:
        "From micro to macro: How do we use DNA to study large animals like tigers",
      description: "Uma Ramakrishna ",
      link:
        "https://www.youtube.com/watch?v=2EwPridYyAk&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=8",
    },
    {
      title: "Emergence of Complexity",
      description: "Sanjay Jain ",
      link: "https://cosmic-zoom.in/events/",
    },
    {
      title: "On Quantum Voyages",
      description: "Smitha Vishveshwara",
      link:
        "https://www.youtube.com/watch?v=8OccTkncdkQ&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=7",
    },
  ];

  return (
    <AnimationRevealPage>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          // backgroundSize: 'cover',
          backgroundRepeat: "repeat",
        }}
      >
        {/* Navbar */}

        <Header links={navLinks} />

        {/* Hero */}
        <TwoColWithButton
          heading={<>Molecules</>}
          description={
            <>
              Molecules are a group of atoms bonded together, representing the
              smallest fundamental unit of a chemical compound.
            </>
          }
          imageSrc={nanoGif}
          imageCss={imageCss}
          textOnLeft={false}
          primaryButtonText="Order Now"
          watchVideoButtonText="Meet The Chefs"
        />

        {/* 1stExhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    Molecules: Creating diversity and complexity
                  </SlantedBackground>
                </Heading>
                <Description>
                  The Cell exhibit explored the many types of cells and their
                  different functions. Cellular functions are driven by
                  biological molecules, for example, proteins. A molecule is a
                  smallest unit into which a substance can be broken down to,
                  without altering its chemical properties. For example, water
                  -- a substance that is vital for our survival, exists as a
                  molecule of hydrogen and oxygen. Two atoms of hydrogen combine
                  with an oxygen atom to form a single molecule of water.
                  Similarly, carbon-di-oxide is a molecule made up of one atom
                  of carbon and two atoms of oxygen. Although there are only 118
                  known elements (among them only 94 exist in nature), matter
                  around us has enormous diversity. This is because this small
                  number of elements can “bond” with each other to form billions
                  of different molecules, some complex enough to support even
                  the origins of life!
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <img src={waterImage} alt="" style={{ borderRadius: "5px" }} />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* add below video */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 2nd Exhibit */}

        <HeaderContainerOther>
          {/* {<SubheadingOther>Exhibit 1</SubheadingOther>} */}
          <DescriptionOther>
            <HeadingOther style={{ textAlign: "center" }}>
              Molecules in the human body
            </HeadingOther>
            Molecules are not just limited to our surroundings but are very much
            a part of our survival and existence. The human body has many
            metabolic and biochemical processes within it, that involve many
            biomolecules that help keep the body functioning like clockwork.:
            <br />
            <i style={{ marginTop: "1rem" }}>
              <b>Haemoglobin: </b>
            </i>
            Haemoglobin is a large sized molecule within our blood cells.
            Haemoglobin binds to oxygen molecules inside the lungs and carries
            it to the cells through the bloodstream. Once they deliver oxygen,
            they carry carbon-di-oxide back to the lungs. <br />
            <i style={{ marginTop: "1rem" }}>
              <b>Adenosine triphosphate (ATP): </b>
            </i>
            ATP is fuel for all organisms including humans. ATP captures
            chemical energy obtained from the breakdown of food molecules, which
            can be utilized by the cells.
            <br />
            <i style={{ marginTop: "1rem" }}>
              <b>Cholesterol: </b>
            </i>
            Cholesterol is a hormone, necessary to make and activate other
            hormones required by our body. It helps in production of bile, an
            enzyme that aids digestion of fats. It also helps in regulating
            emotions, weight, growth and regular mental functioning of an
            individual.
            <br />
            <i style={{ marginTop: "1rem" }}>
              <b>Deoxyribonucleic Acid (DNA): </b>
            </i>
            DNA is a large sized molecule or a macromolecule. Tightly coiled and
            nestled within the nucleus of every cell, DNA carries genetic
            information of an organism or individual. DNA is made of many
            different amino acids and carries the blueprint of the entire
            organism including codes to synthesize all the proteins, enzymes,
            and hormones our body requires.
            <br />
          </DescriptionOther>
        </HeaderContainerOther>

        {/* 3rd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/-LKVUarhtvE?controls=1"
                  background="transparent"
                />
              </LeftColumn>

              <RightColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    Molecules in everyday life
                  </SlantedBackground>
                </Heading>
                <Description>
                  Many of our everyday activities occur at the scale of
                  molecules. Take the example of soap. The ability of soap to
                  effectively separate oil from surfaces is attributed to the
                  unique structure of its molecules. A soap molecule at one end
                  attracts water molecules (hydrophilic) and at the other end
                  attracts lipids (lipophilic). When soap is applied, the soap
                  molecules attach their lipophilic ends to the lipid particles
                  (fats, oils, etc), covering them from all sides. Since the
                  other end of the soap molecule is attached with the water
                  molecules, they get washed away at the end.
                  <br /> Soap has proved to be one of the most effective
                  preventive measures against the COVID-19 virus. The virus’
                  outer surface is a fat layer. Hand washing with soap removes
                  not just grime, but soap molecules also pull apart viruses
                  into many inactive bits.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </RightColumn>
            </TwoColumn>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 4th Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    Molecules that changed the world
                  </SlantedBackground>
                </Heading>
                <Description>
                  <i style={{ marginTop: "1rem" }}>
                    <b>Aspirin: </b>
                  </i>
                  Aspirin is chemically called <i>Acetylsalicylic acid</i> and
                  is used widely across the world as medication to reduce pain.
                  Using aspirin as a painkiller is not a new practice -- even
                  ancient Egyptians used willow bark that contained{" "}
                  <i>salicylic</i>
                  acid to treat aches and pains! The chemically synthesized
                  Acetylsalicylic acid has been used in modern medicine since
                  1915
                  <br />
                  <i style={{ marginTop: "1rem" }}>
                    <b>Penicillin: </b>
                  </i>
                  In 1928, Alexander Fleming, accidently discovered moulds that
                  produce the antibiotic compound penicillin. Called the wonder
                  drug, penicillin is one of the first antibiotics that was
                  effective in treating bacterial infections such as meningitis
                  and pneumonia, which until then were very fatal and had no
                  cure.
                  <br />
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/ezVEzCmiXM4?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>
              {/* add below video */}
              <i style={{ marginTop: "1rem" }}>
                <b>Polyethylene: </b>
              </i>
              Polyethylene is popularly known as plastic. The first industrial
              quality polyethylene was first synthesized by accident in 1933.
              Plastic revolutionised the world as it replaced many expensive and
              rare materials. However, the relentless use of plastics has led to
              the monstrous problem of plastic pollution. Plastics are
              non-biodegradable and stay in the environment for near eternity.
              Nearly 8 million tonnes of plastic find their way into oceans
              every year, choking them up and threatening the survival of marine
              life. Plastic disintegrates over time into minute particles called
              microplastics and finds its way up the food chain to us.
              <br />
              <i style={{ marginTop: "1rem" }}>
                <b>Dichlorodiphenyltrichloroethane (DDT): </b>
              </i>
              DDT is an insecticide, widely used in agriculture during the
              1940s. It was only in 1962, with the publication of Rachel
              Carson’s book the Silent Spring, that the focus of the world was
              drawn to the negative and damaging effects DDT had on the
              environment and health. This led to a ban on use of DDT in the USA
              and many countries across Europe. However, India and many other
              developing countries continue to use DDT mainly to tackle the
              population of mosquitoes.
              <br />
            </DescriptionExtended>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* Talks and Events */}
        <DashedBorderSixFeatures
          cards={cardDetails}
          colorCss={colors}
          bordercolor={bordercolor}
        />

        {/* Quiz link */}
        <GetStarted primaryLinkUrl="https://forms.gle/umMUtjvnhLGdGSq18" />

        {/* Footer */}
        <MiniCenteredFooter />
      </div>
    </AnimationRevealPage>
  );
};

export default Nano;
