import React, { useState, useEffect } from "react";

import tw from "twin.macro";

import "./main.css";

import logo from "../../../src/logo/Logo_FINAL_240_black.png";

// Lottie animation

import Carousel from "components/carousel/Carousel";
// import Carousel2 from "components/carousel/Carousel2";

export const NavLink = tw.a`
cursor-pointer text-lg text-center my-2 lg:text-sm lg:mx-6 lg:my-2
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-red-750 hocus:text-red-750
`;
export const NavLink2 = tw.a`
cursor-pointer text-lg text-gray-400 my-2 lg:text-sm lg:mx-6 lg:my-2 sm:text-sm
xl:font-semibold lg:font-semibold md:font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-gray-400 hocus:text-black
`;

export const FootersContainer = tw.a`
bg-gray-100
grid
grid-cols-3
sm:grid-cols-3 md:grid-cols-7 lg:grid-cols-12 xl:grid-cols-12 
justify-items-center
place-content-center
items-center
`;

export const LogoLink = tw.a`
  font-boldest flex justify-center  items-center text-4xl font-black border-b-0 
`;

const Main = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <>
      {loading === false ? (
        <div
          className="flex flex-col flex-justify h-full overflow-x-hidden "
          // style={{
          //   backgroundImage: `url(${bgDotted})`,
          //   backgroundPosition: "center",
          //   // backgroundSize: "cover",
          //   backgroundRepeat: "repeat",
          // }}
        >
          <div style={{ height: "1vh" }}></div>

          <nav style={{}}>
            <LogoLink href="/">
              <img src={logo} alt="" className="logo anim" />
            </LogoLink>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a href="https://cosmic-zoom.in/events" className="anim navs">
                Events
              </a>
              <a href="/about" className="anim navs">
                About
              </a>
            </div>
          </nav>

          {/* Carousel */}

          <Carousel />

          {/* <Carousel /> */}

          {/* <div style={{ height: "100px" }} /> */}

          {/* Links to other stuff */}

          {/* <Media
            queries={{
              small: "(max-width: 599px)",
              medium: "(min-width: 600px) and (max-width: 1199px)",
              large: "(min-width: 1200px)",
            }}
          >
            {(matches) => (
              <Fragment>
                {matches.small && (
                  <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <FootersContainer>
                      <NavLink href="/subatomic"> Subatomic </NavLink>
                      <NavLink href="/atoms"> Atom </NavLink>
                      <NavLink href="/nano"> Molecule</NavLink>
                      <NavLink href="/cell"> Cell </NavLink>
                      <NavLink href="/insects"> Insect </NavLink>
                      <NavLink href="/human"> Human </NavLink>
                      <NavLink href="/whales"> Whale </NavLink>
                      <NavLink href="/earth"> Earth </NavLink>
                      <NavLink href="/solar-system"> Solar System </NavLink>
                      <NavLink href="/star"> Star </NavLink>
                      <NavLink href="/galaxies"> Galaxy</NavLink>
                      <NavLink href="/cmb">Universe </NavLink>
                    </FootersContainer>
                  </div>
                )}
                {matches.medium && (
                  <div>
                    <div>
                      <FootersContainer>
                        <NavLink href="/subatomic"> Subatomic </NavLink>
                        <NavLink href="/atoms"> Atom </NavLink>
                        <NavLink href="/nano"> Molecule</NavLink>
                        <NavLink href="/cell"> Cell </NavLink>
                        <NavLink href="/insects"> Insect </NavLink>
                        <NavLink href="/human"> Human </NavLink>
                        <NavLink href="/whales"> Whale </NavLink>
                        <NavLink href="/earth"> Earth </NavLink>
                        <NavLink href="/solar-system"> Solar System </NavLink>
                        <NavLink href="/star"> Star </NavLink>
                        <NavLink href="/galaxies"> Galaxy</NavLink>
                        <NavLink href="/cmb"> Universe </NavLink>
                      </FootersContainer>
                    </div>
                  </div>
                )}
                {matches.large && (
                  <div
                  // style={{
                  //   position: "absolute",
                  //   bottom: "0",
                  //   marginLeft: "auto",
                  //   marginRight: "auto",
                  // }}
                  >
                    <div style={{}}>
                      <FootersContainer>
                        <NavLink href="/subatomic"> Subatomic </NavLink>
                        <NavLink href="/atoms"> Atom </NavLink>
                        <NavLink href="/nano"> Molecule</NavLink>
                        <NavLink href="/cell"> Cell </NavLink>
                        <NavLink href="/insects"> Insect </NavLink>
                        <NavLink href="/human"> Human </NavLink>
                        <NavLink href="/whales"> Whale </NavLink>
                        <NavLink href="/earth"> Earth </NavLink>
                        <NavLink href="/solar-system"> Solar System </NavLink>
                        <NavLink href="/star"> Star </NavLink>
                        <NavLink href="/galaxies"> Galaxy</NavLink>
                        <NavLink href="/cmb"> Universe </NavLink>
                      </FootersContainer>
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </Media> */}
        </div>
      ) : (
        <div className="loader-div">
          <div style={{ height: "45vh" }}></div>
          <p className="anim" style={{ textAlign: "center" }}>
            <img
              src={logo}
              alt=""
              style={{ marginLeft: "auto", marginRight: "auto" }}
            />
            Welcome to Cosmic Zoom! We take you for a trip through the Cosmos —
            to the Small and the Big.
          </p>
        </div>
      )}
    </>
  );
};

export default Main;
