import React from "react";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import { SectionDescription2 } from "components/misc/Typography.js";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";
import ReactAudioPlayer from "react-audio-player";
import Header from "components/headers/light.js";

import { NavLinks } from "../../components/headers/light";

import TwoColWithButton from "components/features/TwoColWithButton.js";
import backgroundImage from "../../backgrounds/earth.png";

import earthGif from "../../lotties/earth/3earth.gif";
import ThreeColSimpleWithImage from "components/blogs/ThreeColSimpleWithImage.js";

// bird images

import blackRumpedFlamebackImg from "../../images/scalePages/Black-rumped Flameback.png";
import greyHeadedImg from "../../images/scalePages/grey-headed.png";
import yellowBrowedBulbulImg from "../../images/scalePages/Yellow-browed Bulbul.png";
import redWhiskeredBulbulImg from "../../images/scalePages/red-whiskered-bulbul.png";

// audio

import blackRumpedFlamebackAudio from "../../audio/Black-rumped-flameback-template.mp4";
import greyHeadedCanaryFlycatcherAudio from "../../audio/Grey-headed-canary-flycatcher-template.wav";
import yellowBrowedBulbulAudio from "../../audio/Yellow-browed bulbul template.mp4";
import redWhiskeredBulbulAudio from "../../audio/Red-whiskered bulbul template.wav";

import soundscapeOneAudio from "../../audio/Soundscape1-Protected-Area.WAV";
import soundscapeTwoAudio from "../../audio/Soundscape2-Restored-Landscape.WAV";

import GetStarted from "components/cta/GetStarted.js";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures.js";

// import forestMorningAudio from "../../audio/passively-restored-degraded-forest-morning.WAV";

// import redwhiskeredBulbulAudio from "../../audio/Red-whiskered bulbul template.wav";
// import areaSiteMorningAudio from "../../audio/protected-area-site-morning.WAV";
// import areaSiteNightAudio from "../../audio/protected-area-site-night.WAV";

export const BackLink = tw.a`
    text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
    text-gray-300
    font-semibold tracking-wide transition duration-300
    pb-1 border-transparent hover:border-gray-500 hocus:text-gray-500
  `;

const HeaderContainerOther = tw.div`mt-10 w-full flex flex-col items-center`;
// const SubheadingOther = tw(SubheadingBase)`mb-4`;
const HeadingOther = tw(SectionHeading)`w-full text-center text-white `;
const DescriptionOther = tw(
  SectionDescription2
)`w-10/12 text-justify m-10 text-white`;

//  2 Col with Video

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;

const HeroContainer = tw.div` relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-20  px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full text-center flex flex-col items-center  sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 `;
const Description = tw.p`w-11/12 mt-4 text-justify md:text-justify text-sm md:text-white lg:text-lg font-medium leading-relaxed text-white`;
const DescriptionExtended = tw.p`
w-11/12 px-12 pb-20 text-justify 
md:text-justify text-sm 
md:text-white 
lg:text-lg font-medium leading-relaxed text-white`;

const Heading = styled.h3`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-5xl font-bold text-primary-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-white px-4 -mx-4 py-2`}
  ${
    "" /* &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`} */
  }
  }
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  width: 95%;
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;
// for events and talks
// const ContainerCard = tw.div`text-center content-center ml-48 mr-12`;

// const ThreeColumnContainer = styled.div`
//   ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
// `;
// const HeadingCard = tw(SectionHeading)`w-full text-black`;

// const Column = styled.div`
//   ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
// `;
// const Card = styled.div`
//   ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-black rounded-lg mt-12`}
//   .imageContainer {
//     ${tw`border-2 border-black text-center rounded-full p-6 flex-shrink-0 relative`}
//     img {
//       ${tw`w-8 h-8`}
//     }
//   }

//   .textContainer {
//     ${tw`mt-6 text-center`}
//   }

//   .title {
//     ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
//   }

//   .description {
//     ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
//   }
// `;

const Earth = () => {
  const navLinks = [
    <NavLinks key={1}>
      <BackLink href="/#" tw="lg:ml-12!">
        Home
      </BackLink>
      <BackLink href="https://cosmic-zoom.in/events" tw="lg:ml-12!">
        Events
      </BackLink>
      <BackLink href="/about" tw="lg:ml-12!">
        About
      </BackLink>
      <BackLink href="/whales" tw="lg:ml-12!">
        Smaller
      </BackLink>
      <BackLink href="/solar-system" tw="lg:ml-12!">
        Larger
      </BackLink>
    </NavLinks>,
  ];

  const colors = { color: "#fff" };
  const bordercolor = { borderColor: "#fff" };

  const cardDetails = [
    {
      title: "The life of whales and dolphins",
      description: "Dipani Sutaria",
      link: "https://cosmic-zoom.in/events/",
    },
    {
      title: "Cloudy and turbulent days, and the wait for rain",
      description: "Rama Govindarajan",
      link: "https://cosmic-zoom.in/events/",
    },
    {
      title: "Have You Ever Heard a Whale Sing?",
      description: "Divya Panicker",
      link:
        "https://www.youtube.com/watch?v=YzlZ9qNpGIA&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=10",
    },
    {
      title:
        "From micro to macro: How do we use DNA to study large animals like tigers",
      description: "Uma Ramakrishnan",
      link:
        "https://www.youtube.com/watch?v=2EwPridYyAk&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=8",
    },
    {
      title: "Project Dhvani: Forest soundscapes from the Western Ghats",
      description: "Vijay Ramesh",
      link:
        "https://www.youtube.com/watch?v=-lxXAtGQ6Ok&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=6",
    },
  ];

  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-full transform scale-75 `;
  const HeadingCss = tw`text-white`;
  const DescriptionCss = tw`text-white `;

  const birds = [
    {
      imageSrc: blackRumpedFlamebackImg,
      imagePresent: true,
      audio: blackRumpedFlamebackAudio,
      title: "Black-Rumped Flame Back",
    },
    {
      imageSrc: greyHeadedImg,
      imagePresent: true,
      audio: greyHeadedCanaryFlycatcherAudio,
      title: "Grey headed canary flycatcher",
    },
    {
      imageSrc: yellowBrowedBulbulImg,
      imagePresent: true,
      audio: yellowBrowedBulbulAudio,
      title: "Yellow browed bulbul",
    },
    {
      imageSrc: redWhiskeredBulbulImg,
      imagePresent: true,
      audio: redWhiskeredBulbulAudio,
      title: "Red Whiskered Bulbul",
    },
  ];

  return (
    <AnimationRevealPage>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          // backgroundSize: 'cover',
          backgroundRepeat: "repeat",
        }}
      >
        {/* Navbar */}

        <Header links={navLinks} />

        {/* Hero */}
        <TwoColWithButton
          heading={<>Earth</>}
          description={
            <>
              <i>
                “It suddenly struck me that that tiny pea, pretty and blue, was
                the Earth. I put up my thumb and shut one eye, and my thumb
                blotted out the planet Earth. I didn’t feel like a giant. I felt
                very, very small.” <br />
              </i>
              <b> – Neil Armstrong</b>, First Human to Walk on the Moon <br />
              Earth is the only planet known to us that supports life. Life on
              earth occurs in various forms ﹣ from microscopic organisms like
              bacteria to large mammals such as the Blue Whale. Scientists
              estimate that there are about 8.7 million species of flora and
              fauna, but only a small fraction of it have been discovered and
              identified so far
            </>
          }
          imageSrc={earthGif}
          imageCss={imageCss}
          HeadingCss={HeadingCss}
          DescriptionCss={DescriptionCss}
          textOnLeft={false}
          primaryButtonText="Order Now"
          watchVideoButtonText="Meet The Chefs"
        />

        {/* 1st Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>Earth or Oceania?</SlantedBackground>
                </Heading>
                <Description>
                  The Earth is unique in many ways. It has that one vital
                  ingredient that allows it to support life﹣ Water. The word
                  Earth comes from an ancient Anglo-saxon word for soil. But the
                  fact is that 71% of the Earth’s surface is water! In fact
                  “Earth” is misleading, technically it should be called
                  “Oceania”. Despite its molecular simplicity, water has
                  remarkable properties that led to the <i>Water Cycle</i> which
                  pretty much powers all life on Earth. Most of the water is
                  stored in the form of ice which is densely packed in the
                  northern and southern poles. If you thought Earth already had
                  a lot of water, you would be shocked to learn what would
                  happen if that ice melted!
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/b6CPsGanO_U?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
            <DescriptionExtended>{/* add below video */}</DescriptionExtended>
          </HeroContainer>
        </Container>

        {/* 2nd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/yhlg9txl7yM?controls=1"
                  background="transparent"
                />
              </LeftColumn>

              <RightColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>Carbon and our Climate</SlantedBackground>
                </Heading>
                <Description>
                  One of the biggest byproducts of our amazing technological and
                  engineering evolution is unfortunately the emission of carbon
                  dioxide and methane. Nature has ways of bringing these gases
                  back to the Earth ﹣ for example, plants convert carbon
                  dioxide into biomass, thus removing it from the atmosphere.
                  After billions of years of a naturally balanced carbon cycle,
                  in just a few hundred years human activities have led to a
                  catastrophic increase of carbon in the atmosphere which is
                  rapidly altering Earth’s climate. Carbon dioxide and methane
                  are known to trap heat in the atmosphere. As a result, summers
                  are generally getting hotter, warmer temperatures fuel longer
                  and more destructive fire seasons, shifting rain patterns
                  cause flooding in some areas and drought in others, and oceans
                  are getting warmer and more acidic which threatens marine life
                  across the globe. A carbon sink is any reservoir that captures
                  and stores carbon, preventing it from being part of the
                  destructive cycle. Forests are huge eco-systems which are some
                  of the best carbon sinks, but are unfortunately being cut down
                  relentlessly. Why is it necessary to keep an eye on the carbon
                  cycle and how can we help contain it?
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </RightColumn>
            </TwoColumn>
          </HeroContainer>
        </Container>

        {/* 3rd Exhibit: Soundscapes */}

        <HeaderContainerOther>
          {/* {<SubheadingOther>Exhibit 1</SubheadingOther>} */}
          <DescriptionOther>
            <HeadingOther>Soundscape: A new conservation tool</HeadingOther>
            Forests hold within them most of the world’s biodiversity. The
            evergreen forests in India's Western Ghats is a global biodiversity
            hotspot. Home to many species unique to the region, these forests
            resonate with sounds of many different birds, insects, frogs, etc.
            However, with increasing threat from human presence and activities,
            forest patches in the Western Ghats have undergone changes in terms
            of the biodiversity they support. These changes get reflected in the
            sounds that one can hear in these areas. Recently, sound recordings
            from forests are being used by ecologists as a conservation tool.
            They believe that the sounds collected over a long period will help
            document changes occurring in the landscape due to anthropogenic
            pressures and climate change.
            <p style={{ paddingTop: "10px" }}>
              Here we have sounds captured from two distinct locations in the
              Nilgiris, in south India. The first audio clip is from a
              soundscape recorded in a protected old growth forest, where human
              interference is minimum. The second one is from a forest fragment
              vulnerable to anthropogenic threats. Can you detect the
              differences between these two soundscapes?
            </p>
            <br />
            <section
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div style={{ margin: "auto" }}>
                <h2 style={{ textAlign: "center" }}>Protected Area</h2>
                <ReactAudioPlayer
                  src={soundscapeOneAudio}
                  // autoPlay
                  controls
                />
              </div>
              <div style={{ margin: "auto" }}>
                <h2 style={{ textAlign: "center" }}>
                  Restored Forest Fragment
                </h2>
                <ReactAudioPlayer
                  src={soundscapeTwoAudio}
                  // autoPlay
                  controls
                />
              </div>
            </section>
            <p style={{ fontSize: "16px", textAlign: "center" }}>
              The soundscapes shared in this exhibit were captured in the
              Anamalais, Western Ghats and is a part of a larger collaborative
              effort called{" "}
              <a
                href="https://projectdhvani.weebly.com/meet-the-team.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "blanchedalmond" }}
              >
                Project Dhvani
              </a>{" "}
              . Project Dhvani aims to monitor biodiversity through sounds with
              current focus on landscapes of Western Ghats and Central India
            </p>
            <br />
            The sounds of birds are the most prominently heard in these
            soundscapes. Some bird species like the grey headed canary
            flycatcher and the yellow browed bulbul are specialists and thrive
            in forest patches that have thick canopies and low human presence.
            Other species like the red-whiskered bulbul and the black-rumped
            flame back (a woodpecker species) are more flexible in their ways
            and are able to cope with higher levels of anthropogenic
            disturbance.
          </DescriptionOther>
        </HeaderContainerOther>

        <ThreeColSimpleWithImage content={birds} />
        <p
          style={{
            color: "#fff",
            width: "60%",
            margin: "auto",
            paddingTop: "2rem",
            textAlign: "center",
          }}
        >
          Soundscapes courtesy:{" "}
          <a
            href="https://projectdhvani.weebly.com/meet-the-team.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontWeight: "bold" }}
          >
            {" "}
            Project Dhvani
          </a>
          , Akshay Anand for data annotation and Dhanya Bharath for data
          curation.
        </p>

        {/* 3rd Exhibit */}

        <HeaderContainerOther>
          <DescriptionOther>
            Soundscapes are not restricted to terrestrial ecosystems. Marine
            ecosystems consist of many sounds too. Marine mammals like whales
            and dolphins communicate with others of their kind by emitting high
            frequency sound waves, a technique called echolocation. The same
            method is used by ships and submarines to navigate their sea-routes.
            Check our exhibit on whales to learn more.
          </DescriptionOther>
        </HeaderContainerOther>

        <div style={{ height: "50px" }}></div>

        {/* Quiz link */}
        <GetStarted primaryLinkUrl="https://forms.gle/PLaeAayNLRbsES5a9" />

        {/* Talks and Events */}
        <DashedBorderSixFeatures
          cards={cardDetails}
          colorCss={colors}
          bordercolor={bordercolor}
        />

        {/* Footer */}
        <MiniCenteredFooter />
      </div>
    </AnimationRevealPage>
  );
};

export default Earth;
