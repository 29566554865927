import React from "react";
import tw from "twin.macro";
import "./about.css";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import { NavLinks } from "../../components/headers/light";
import ICTSlogo from "../../images/scalePages/icts.png";
import logo from "../../logo/Logo_FINAL_240_black.png";

export const BackLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  text-gray-300
  font-semibold tracking-wide transition duration-300
  pb-1 border-transparent hover:border-gray-500 hocus:text-gray-500
`;

const About = () => {
  const navLinks = [
    <NavLinks key={1}>
      <BackLink href="/#" tw="lg:ml-12!">
        Home
      </BackLink>
      <BackLink href="https://cosmic-zoom.in/events" tw="lg:ml-12!">
        Events
      </BackLink>
    </NavLinks>,
  ];

  return (
    <AnimationRevealPage>
      <div
        style={
          {
            //   backgroundImage: `url(${backgroundImage})`,
            //   backgroundPosition: "center",
            //   // backgroundSize: 'cover',
            //   backgroundRepeat: "repeat",
          }
        }
      >
        {/* navbar */}

        <Header links={navLinks} />

        <div style={{ height: "5vh" }}></div>

        <div className="centered">
          <img src={logo} alt="" className="logo" />
        </div>

        <section>
          <div className="centered">
            <p>Concept & Curation</p>
            <b className="names">Ajith Parameswaran </b>
          </div>

          <div className="centered">
            <p>Exhibition Design</p>
            <b className="names">Samhitha Kottamasu </b>
          </div>
          <div className="centered">
            <p>Graphic & Animation Design </p>
            <b className="names">Juny K. Wilfred</b>
          </div>

          <div className="centered">
            <p>Web Design & Development </p>
            <b className="names">Ram Shankar Choudhary </b>
          </div>
          <div className="centered">
            <p>Graphics & Animation </p>
            <b className="names">
              Kartik Chati, Nathan Calisto Gomes, Samhitha Kottamasu, Juny K.
              Wilfred
            </b>
          </div>
          <div className="centered">
            <p>Content Writing </p>
            <b className="names">
              Berty Ashley, Ipsita Herlekar, Ajith Parameswaran
            </b>
          </div>
          <div className="centered">
            <p>Content Contribution </p>
            <b className="names">
              Soummyadip Basak, Kanhaiya Lal Pandey, Aditya Vijaykumar
            </b>
          </div>

          <div className="centered">
            <p>Content Editing </p>
            <b className="names">Ananya Dasgupta </b>
          </div>

          <div className="centered">
            <p>Content Review </p>
            <b className="names">
              Axel Brockman, Rukmini De, Avinash Dhar, Surhud More, Divya
              Panicker, Prajval Shastri, Manoj Puravankaral, Sivarani T
            </b>
          </div>
          <div className="centered">
            <p>Web Hosting & Development </p>
            <b className="names">Srinivasa R, Kusuma Manjunath </b>
          </div>

          <div className="centered">
            <p>Event Coordination </p>
            <b className="names">Berty Ashley, Anupam Ghosh</b>
          </div>

          <div className="centered">
            <p>Event Technical Support </p>
            <b className="names">
              Arun B, Gobinath M, Naveen Kumar L C, Shantaraj S
            </b>
          </div>
          <div className="centered">
            <p>Mentoring </p>
            <b className="names">
              Rajesh Gopakumar, Mukund Thattai, Jahnavi Phalkey, Spenta Wadia{" "}
            </b>
          </div>
          <div className="centered">
            <p>Consultants </p>
            <b className="names">
              Amit Apte, Uma Ramakrishnan, Amitabh Joshi, Joseph Samuel, Supurna
              Sinha, N. S. Vidhyadhiraja, Prajval Shastri
            </b>
          </div>
          <div className="centered">
            <p>Produced by </p>
            <img src={ICTSlogo} alt="" className="icts" />
          </div>
        </section>
        <div style={{ height: "10vh" }}></div>

        {/* Footer */}
        <MiniCenteredFooter />
      </div>
    </AnimationRevealPage>
  );
};

export default About;
