import React from "react";

import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";

import Header from "components/headers/light.js";
import { NavLinks } from "../../components/headers/light";

import TwoColWithButton from "components/features/TwoColWithButton.js";
import backgroundImage from "../../backgrounds/cell.png";

import cellGif from "../../lotties/cell/Comp 1.gif";
import GetStarted from "components/cta/GetStarted.js";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures.js";

export const BackLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  text-gray-300
  font-semibold tracking-wide transition duration-300
  pb-1 border-transparent hover:border-gray-500 hocus:text-gray-500
`;

// const HeaderContainerOther = tw.div`mt-10 w-full flex flex-col items-center`;
// const SubheadingOther = tw(SubheadingBase)`mb-4`;
// const HeadingOther = tw(SectionHeading)`w-full text-left text-black `;
// const DescriptionOther = tw(
//   SectionDescription2
// )`w-11/12 text-justify m-10 text-black`;

//  2 Col with Video

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;

// const TextContent = tw.p`pt-2 text-justify md:text-justify text-sm md:text-black lg:text-lg font-medium leading-relaxed text-black`;

const HeroContainer = tw.div` relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-20  px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full text-center flex flex-col items-center  sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 `;
const Description = tw.p`w-11/12 mt-4 text-justify md:text-justify text-sm md:text-black lg:text-lg font-medium leading-relaxed text-black`;
// const DescriptionExtended = tw.p`
// w-full px-12 pb-20 text-justify
// md:text-justify text-sm
// md:text-black
// lg:text-lg font-medium leading-relaxed text-black`;

const Heading = styled.h3`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-5xl font-bold text-primary-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-black px-4 -mx-4 py-2`}
  ${
    "" /* &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`} */
  }
  }
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  width: 95%;
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

// const TableContainer = tw.table`
// table-auto
// `;

const Cell = () => {
  const navLinks = [
    <NavLinks key={1}>
      <BackLink href="/#" tw="lg:ml-12!">
        Home
      </BackLink>
      <BackLink href="https://cosmic-zoom.in/events" tw="lg:ml-12!">
        Events
      </BackLink>
      <BackLink href="/about" tw="lg:ml-12!">
        About
      </BackLink>
      <BackLink href="/nano" tw="lg:ml-12!">
        Smaller
      </BackLink>
      <BackLink href="/insects" tw="lg:ml-12!">
        Larger
      </BackLink>
    </NavLinks>,
  ];

  const colors = { color: "#000" };
  const bordercolor = { borderColor: "#000" };

  const cardDetails = [
    {
      title:
        "From micro to macro: How do we use DNA to study large animals like tigers",
      description: "Uma Ramakrishna ",
      link:
        "https://www.youtube.com/watch?v=2EwPridYyAk&list=PL04QVxpjcnjhmBuwp4sTUxcoySwK-dA2g&index=8",
    },
    {
      title: "EFG",
      description: "Shashi Thutupalli, ICTS   ",
      link: "https://cosmic-zoom.in/events/",
    },
  ];

  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-full transform scale-75 `;
  const HeadingCss = tw`text-black`;
  const DescriptionCss = tw`text-black `;

  return (
    <AnimationRevealPage>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          // backgroundSize: 'cover',
          backgroundRepeat: "repeat",
        }}
      >
        {/* Navbar */}

        <Header links={navLinks} />

        {/* Hero */}
        <TwoColWithButton
          heading={<>The Cell</>}
          description={
            <>
              The origin of life on Earth started with a single cell --- the
              smallest functional and basic unit of life. Check this exhibit to
              find out more on what’s inside a cell.
            </>
          }
          imageSrc={cellGif}
          imageCss={imageCss}
          HeadingCss={HeadingCss}
          DescriptionCss={DescriptionCss}
          textOnLeft={false}
          primaryButtonText="Order Now"
          watchVideoButtonText="Meet The Chefs"
        />

        {/* 1stExhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>What’s inside a cell?</SlantedBackground>
                </Heading>
                <Description>
                  A single cell can function on its own --- as in single-celled
                  organisms like bacteria and algae. Itcan also function
                  collectively --- like in multi-celled organisms including
                  humans. The functioning of a living cell is best explained by
                  comparing it to a factory. Just like different departments of
                  a factory work in coordination to put together a product, the
                  cell contains many components that work in tandem to keep the
                  cell functioning. The inside of a cell is a crowded space. Cut
                  a cell open and you will see it packed with many different
                  subunits called <i> cell organelles </i>, each with a
                  specialized function. Together they keep the cell functioning
                  like clockwork.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/URUJD5NEXC8?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 2nd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/wNe6RuK0FfA?controls=1"
                  background="transparent"
                />
              </LeftColumn>

              <RightColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    Specialized cells: Same but different
                  </SlantedBackground>
                </Heading>
                <Description>
                  An adult human body is made up of around 37 trillion cells.
                  Though all eukaryotic (cells where the nucleus is surrounded
                  by a nuclear membrane) cells have the same basic structure and
                  function, some cells are specialized to carry out specific
                  functions. The human body has over 200 different types of
                  specialized cells found in different parts of the body. For
                  example, the kind of cells found inside your lungs perform a
                  different function from those found in the skin; the blood
                  cells are different in size, shape and function from cells
                  found in the bone. Similarly in plants, the cells found in the
                  leaves are different from those found in the roots, owing to
                  the different roles played by different parts of the plant.
                </Description>
                <br />
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </RightColumn>
            </TwoColumn>
          </HeroContainer>
        </Container>

        {/* 3rd Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>The treasured centre</SlantedBackground>
                </Heading>
                <Description>
                  Tightly coiled within the nucleus of a cell is the stuff that
                  defines us --- Deoxyribonucleic Acid (DNA). It stores within
                  it, coded genetic information of an individual and is passed
                  from parents to their offspring. In the nucleus, DNA is packed
                  inside thread-like strands made of proteins called
                  chromosomes. Interestingly, the number of chromosomes varies
                  from species to species and it doesn’t seem to relate to the
                  complexity of the organism. For example, humans have 46
                  chromosomes, while fruit flies have only 6 and Pigeons have
                  80! Can you imagine a string like structure, longer than a
                  tall man, coiled tightly inside every cell in our body? In
                  humans, DNA measures up to two meters in length. This video
                  explains how a long strand of DNA is coiled and packed within
                  the nucleus of every cell.
                </Description>
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </LeftColumn>

              <RightColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/NJ-LqO51QCM?controls=1"
                  background="transparent"
                />
              </RightColumn>
            </TwoColumn>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* 4th Exhibit */}

        <Container>
          {/* <OpacityOverlay /> */}
          <HeroContainer>
            <TwoColumn>
              <LeftColumn>
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/jAhjPd4uNFY?controls=1"
                  background="transparent"
                />
              </LeftColumn>

              <RightColumn>
                {/* <Notification>We have now launched operations in Europe.</Notification> */}
                <Heading>
                  <SlantedBackground>
                    Snipping genes: The future of CRISPR
                  </SlantedBackground>
                </Heading>
                <Description>
                  Genes are the banks of information all living organisms have
                  that encode how their life progresses. These are made up of
                  DNA which codes for the production of the basic units of
                  proteins – Amino Acids. By studying how this system works we
                  can elucidate which part of the DNA is responsible for what
                  action in your body. Till recently we didn’t have a precise
                  method to do so. This changed with the discovery of the
                  CRISPR-Cas9 system, a system that evolved in Bacteria as a
                  defense mechanism which consists of two molecules – a guide
                  RNA that guides the enzyme Cas9 to a precise part of the
                  genome to make a cut. Researchers now have a fast and
                  inexpensive method to make changes in DNA and study the
                  effects which has the potential to transform medicine,
                  enabling us to not only treat but also prevent many diseases.
                  We are now standing at the cusp of personalized medicine
                  becoming a normal occurrence
                </Description>
                <br />
                {/* <PrimaryAction>CTA</PrimaryAction> */}
              </RightColumn>
            </TwoColumn>
          </HeroContainer>
        </Container>

        <div style={{ height: "50px" }}></div>

        {/* Talks and Events */}
        <DashedBorderSixFeatures
          cards={cardDetails}
          colorCss={colors}
          bordercolor={bordercolor}
        />

        {/* Quiz link */}
        <GetStarted primaryLinkUrl="https://forms.gle/KV8PfrB5RGzyqMor7" />

        {/* Footer */}
        <MiniCenteredFooter />
      </div>
    </AnimationRevealPage>
  );
};

export default Cell;
