import React, { useState, Fragment } from "react";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Media from "react-media";

// import GifPlayer from "react-gif-player";

import "./carousel.css";

import atomGif from "../../lotties/atom/Comp 1.gif";
import cellGif from "../../lotties/cell/Comp 1.gif";
import cmbGif from "../../lotties/cmb/Comp-1.gif";
import earthGif from "../../lotties/earth/Comp 1.gif";
import galaxyGif from "../../lotties/galaxy/galaxy.gif";
import insectGif from "../../lotties/insects/Comp 1_1.gif";
import nanoGif from "../../lotties/nano/Comp-1.gif";
import solarGif from "../../lotties/solar-system/Comp 1.gif";
import whaleGif from "../../lotties/whale/Comp-1.gif";
import starGif from "../../lotties/star/star.gif";
import subAtomicGif from "../../lotties/subatomic/sub-atomic.gif";
import humanPng from "../../lotties/human/Human-01.png";

const details = [
  {
    id: 1,
    gifName: "normal",
    size: "1",
    superscript: "",
    image: humanPng,
    name: "Human",
    desc:
      "We start this journey at the familiar scale of a meter — roughly the height of a curious five-year old.  A remarkable trait of our species is curiosity. Our journey will be guided by two questions: What are things made of? and What is our place in the Universe? We will also find surprising connections between the micro and macro worlds.",
    link: "/human",
  },
  {
    id: 2,
    gifName: "normal",
    size: "10",
    superscript: "-2",
    image: insectGif,
    name: "Insect",
    desc:
      "At the scale of a centimeter — one hundredths of a meter, we reach the world of insects. You might think that they are creepy and crawly. But there is no denying that insects have fascinating lives. ",
    link: "/insects",
  },
  {
    id: 3,
    gifName: "normal",
    size: "10",
    superscript: "-5",
    image: cellGif,
    name: "Cell",
    desc:
      "All living things are made of fundamental building blocks of life, called cells. For example, our blood cells measure about 10 micrometers — one thousandth  of a centimeter. At this scale, things are no longer visible to the naked eye. You will need a microscope.",
    link: "/cell",
  },
  {
    id: 4,
    gifName: "normal",
    size: "10",
    superscript: "-9",
    image: nanoGif,
    name: "Molecules",
    desc:
      "All the stuff that we see around us, including cells, are made of tiny molecules, which have sizes of a millionth of a meter. These include the water that we drink and the DNA that carries our genetic information.  At these scales, you will need a powerful electron microscope. ",
    link: "/nano",
  },
  {
    id: 5,
    gifName: "normal",
    size: "10",
    superscript: "-10",
    image: atomGif,
    name: "Atom",
    desc:
      "Molecules are made of atoms — the smallest constituent unit of ordinary matter that has the properties of a chemical element.  The billions of types of molecules around us are made of just about 100 types of atoms! ",
    link: "/atoms",
  },
  {
    id: 6,
    gifName: "normal",
    size: "10",
    superscript: "-18",
    image: subAtomicGif,
    name: "Subatomic particles",
    desc:
      "Although atoms were once thought of as the fundamental building blocks of matter, we now know that they are made of even smaller particles. These subatomic particles are beyond the reach of even the most powerful microscopes. This extreme micro regime is intimately connected to  the Universe at the largest scales. ",
    link: "/subatomic",
  },
  {
    id: 7,
    gifName: "normal",
    size: "10",
    superscript: "26",
    image: cmbGif,
    name: "The Universe",
    desc:
      "The size of the Observable Universe is about 1026 meters. The distribution of galaxies in the Universe — the  large scale structure —  is strongly influenced by what happened in the early Universe at extreme small scales. ",
    link: "/cmb",
  },
  {
    id: 8,
    gifName: "normal",
    size: "10",
    superscript: "20",
    image: galaxyGif,
    name: "Galaxy",
    desc:
      "Our galaxy, the Milky Way, is one among trillions of galaxies in the Universe. Each galaxy contains hundreds of billions of stars and planets, huge amounts of gas and dust, apart from  the mysterious dark matter. ",
    link: "/galaxies",
  },
  {
    id: 9,
    gifName: "normal",
    size: "10",
    superscript: "13",
    image: solarGif,
    name: "Solar System",
    desc:
      "Our solar system is located in the Orion spiral arm of the Milky Way. A gravitationally bound system, it includes eight planets, many satellites, asteroids, and dwarf planets, all revolving around our home star, the Sun. ",
    link: "/solar-system",
  },
  {
    id: 10,
    gifName: "abnormal",
    size: "10",
    superscript: "9",
    image: starGif,
    name: "Stars",
    desc:
      "Stars are big burning balls of gas, powered by nuclear reactions happening in their cores.  Our Sun is only one among the hundred billion stars in our galaxy. ",
    link: "/star",
  },
  {
    id: 11,
    gifName: "normal",
    size: "10",
    superscript: "7",
    image: earthGif,
    name: "Earth",
    desc:
      "The Blue Planet is one among the eight planets in our Solar System — “a very small stage in a vast cosmic arena.” This is the only planet that is known to support the strange and complex phenomenon, called life. ",
    link: "/earth",
  },
  {
    id: 12,
    gifName: "normal",
    size: "10",
    superscript: "",
    image: whaleGif,
    name: "Whale",
    desc:
      "Tens of times longer than humans, whales are the largest animals ever lived on Planet Earth. These majestic cousins of ours exhibit a range of complex behavior. ",
    link: "/whales",
  },
];

const Carousel = () => {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settingsBig = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 5,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
    adaptiveHeight: true,
  };
  const settingsMedium = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
    adaptiveHeight: true,
  };
  const settingsSmall = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
    adaptiveHeight: true,
  };

  return (
    <div className="carousel-container">
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)",
        }}
      >
        {(matches) => (
          <Fragment>
            {matches.small && (
              <section style={{ margin: 0, padding: 0 }}>
                <Slider {...settingsSmall}>
                  {details.map((item, index) => (
                    <div
                      className={
                        index === imageIndex
                          ? "slide activeSlide"
                          : "slide inactiveSlide"
                      }
                    >
                      <Link to={item.link}>
                        {/* <GifPlayer
                        gif={item.image}
                        autoplay
                    // still={images2.starSvg}
                    // pauseRef
                    /> */}

                        <img
                          src={item.image}
                          className={
                            item.gifName === "normal" ? "normal" : "abnormal"
                          }
                          alt={item.image}
                          key={index}
                        />
                      </Link>

                      <div className="desc" style={{}}>
                        <Link to={item.link}>
                          <h2
                            class="size texts metric"
                            style={{ fontSize: "40px", color: "#cbd5e0" }}
                          >
                            {item.size}
                            <sup>{item.superscript}</sup>m
                          </h2>

                          <h3
                            className="bash-name texts"
                            style={{ fontSize: "50px", fontWeight: "800" }}
                          >
                            {item.name}
                          </h3>
                        </Link>
                        <p
                          className="bash-description texts"
                          style={{
                            width: "650px",
                            paddingRight: "3rem",
                            paddingLeft: "3rem",
                            fontSize: " 30px",
                            lineHeight: "1.1",
                          }}
                        >
                          {" "}
                          <span> {item.desc} </span>
                        </p>
                        {/* <p className="click-notify">
                        Click the image to animate ✨
                        </p> */}
                      </div>
                    </div>
                  ))}
                </Slider>
              </section>
            )}
            {matches.medium && (
              <section style={{ margin: 0, padding: 0 }}>
                <Slider {...settingsMedium}>
                  {details.map((item, index) => (
                    <div
                      className={
                        index === imageIndex
                          ? "slide activeSlide"
                          : "slide inactiveSlide"
                      }
                    >
                      <Link to={item.link}>
                        {/* <GifPlayer
                            gif={item.image}
                            autoplay
                        // still={images2.starSvg}
                        // pauseRef
                        /> */}

                        <img
                          src={item.image}
                          className={
                            item.gifName === "normal" ? "normal" : "abnormal"
                          }
                          style={{}}
                          alt={item.image}
                          key={index}
                        />
                      </Link>

                      <div className="desc" style={{}}>
                        <Link to={item.link}>
                          <h2
                            class="size texts metric"
                            style={{ fontSize: "30px", color: "#cbd5e0" }}
                          >
                            {item.size}
                            <sup>{item.superscript}</sup>m
                          </h2>

                          <h3
                            className="bash-name texts"
                            style={{
                              fontSize: "30px",
                              fontWeight: "800",
                              fontFamily: "Raleway",
                            }}
                          >
                            {item.name}
                          </h3>
                        </Link>
                        <p
                          className="bash-description texts"
                          style={{
                            width: "70vw",
                            fontSize: " 20px",
                            fontWeight: "400",
                          }}
                        >
                          {item.desc}
                        </p>
                        {/* <p className="click-notify">
                        Click the image to animate ✨
                        </p> */}
                      </div>
                    </div>
                  ))}
                </Slider>
              </section>
            )}
            {matches.large && (
              <section style={{ margin: 0, padding: 0 }}>
                <Slider {...settingsBig}>
                  {details.map((item, index) => (
                    <div
                      className={
                        index === imageIndex
                          ? "slide activeSlide"
                          : "slide inactiveSlide"
                      }
                    >
                      <Link to={item.link}>
                        {/* <GifPlayer
                                            gif={item.image}
                                            autoplay
                                        // still={images2.starSvg}
                                        // pauseRef
                                        /> */}

                        <img
                          src={item.image}
                          className={
                            item.gifName === "normal" ? "normal" : "abnormal"
                          }
                          alt={item.image}
                          key={index}
                        />
                      </Link>

                      <div className="desc">
                        <div style={{ height: "1rem" }}></div>
                        <Link to={item.link}>
                          <h2
                            class="size texts metric"
                            style={{ fontSize: "90px", color: "#cbd5e0" }}
                          >
                            {item.size}
                            <sup>{item.superscript}</sup>m
                          </h2>

                          <h3
                            className="bash-name texts"
                            style={{ fontSize: "30px", fontWeight: "800" }}
                          >
                            {item.name}
                          </h3>
                        </Link>
                        <p
                          className="bash-description texts"
                          style={{
                            width: "70vw",
                            fontSize: " 20px",
                            fontWeight: "400",
                          }}
                        >
                          {item.desc}
                        </p>
                        {/* <p className="click-notify">
                                Click the image to animate ✨
                            </p> 
                        */}
                      </div>
                    </div>
                  ))}
                </Slider>
              </section>
            )}
          </Fragment>
        )}
      </Media>
    </div>
  );
};

export default Carousel;
